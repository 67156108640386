import { Button, message, Layout, Col, Form, Input } from "antd";
import "antd/dist/antd.css";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
// Ant Design
import TrayaLogo from "../../../assets/TrayaLogo.png";
import Presv1 from "../../../assets/pres_v2.png";
import Presv2 from "../../../assets/pres_v1.png";
import { baseURL, SECURITY_TOKEN } from "../../../helpers/api-config";
import { HiInformationCircle } from "react-icons/hi";
import HomePageAccordian from "../../../components/HomePageAccordian";
// Ends

const { Header } = Layout;

const Dashboard = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [orderId, setOrderId] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [datas, setDatas] = useState([]);
  const [messages, setMessages] = useState("");
  const [phoneMessage, setPhoneMessage] = useState("");
  const [isMessageVisible, setMessageVisible] = useState(false);

  const handleOrderIdChange = ({ target }) => {
    setOrderId("#" + target.value);
  };

  const handlePhonenoChange = ({ target }) => {
    setPhoneno(target.value);
  };

  const createPrescription = async (phoneno, orderId) => {
    try {
      const _res = await fetch(`${baseURL.API_URL_BASE}user-info-by-order`, {
        method: "POST",
        json: true,
        body: JSON.stringify({
          phoneNumber: phoneno.trim(),
          orderDisplayId: orderId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${SECURITY_TOKEN}`,
        },
      });
      const data = await _res.json();
      if (_res.status === 200) {
        if (data) {
          let dataArr = [];
          dataArr.push(data);
          setDatas(dataArr);
          history.push(
            `/prescription?userId=${data.order.user_id}&orderId=${data.order.id}`
          );
        }
      } else if (_res.status === 400) {
        if (data.message === `Order ${orderId} not exists for this customer`) {
          setMessageVisible(true);
        }
        setMessages(data.message);
      } else {
        setMessages(data.message);
      }
      return _res;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async () => {
    let isValueCheck = false;
    if (phoneno.length > 10 || phoneno.length < 10) {
      isValueCheck = false;
      setPhoneMessage("Please enter valid phone number");
    } else if (phoneno.length === 10) {
      isValueCheck = true;
    }

    if (isValueCheck) {
      const res = await createPrescription(phoneno, orderId);

      if (res.status === 200) {
        form.resetFields();
      }
    }
  };

  return (
    <>
      <Layout style={{ backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={TrayaLogo}
              alt="Logo"
              style={{
                marginTop: "1.5%",
                padding: "14px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="dashboardBar"></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="topText">Hi there,</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span className="headerText">
              Doctors have approved your plan. Find out the exact dosage and
              instructions on how to use below.
            </span>
          </div>
          <div>
            <Form
              form={form}
              onFinish={handleSubmit}
              style={{
                height: "100vh",
                textAlign: "center",
              }}
              className="form-box"
            >
              <div
                className="form-prescription-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  margin: "0 auto",
                }}
              >
                <p className="subHeaderText">To get your prescription</p>
                <Form.Item
                  name="phoneno"
                  rules={[
                    {
                      required: true,
                      message: "*Please enter your phone number",
                      marginLeft: "60px",
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message:
                        "*Enter your phone number without country code (eg : 8828006272)",
                      marginLeft: "60px",
                    },
                    {
                      whitespace: true,
                      message: "*Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input
                    className="inputwidth"
                    placeholder="Enter phone number"
                    onChange={handlePhonenoChange}
                    value={"+91" + phoneno}
                    prefix="+91"
                  />
                </Form.Item>
                {phoneMessage && (
                  <span
                    style={{
                      width: "322px",
                      height: "68px",
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "162.9%",
                      textAlign: "center",
                      color: "#FF0000",
                    }}
                  >
                    *{phoneMessage}
                  </span>
                )}
                <br />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div>
                    <HiInformationCircle
                      size={"23px"}
                      color="#B7D340"
                      style={{ marginTop: "6%" }}
                    />
                  </div>
                  <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "12px",
                      lineHeight: "162.9%",
                      textAlign: "left",
                      color: "#414042",
                      opacity: " 0.7",
                      marginLeft: "5px",
                      marginTop: "3.5px",
                    }}
                    className="infoText"
                  >
                    Looking for your order id? Check our SMS/ Whatsapp
                  </p>
                </div>

                <Form.Item
                  name="orderId"
                  rules={[
                    {
                      required: true,
                      message: "*Please enter your Order ID",
                    },
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "*Enter your Order ID without # (eg : 325530)",
                    },
                    {
                      whitespace: true,
                      message: "*Please enter a valid Order ID",
                    },
                  ]}
                >
                  <Input
                    className="inputwidth"
                    placeholder="Enter order ID"
                    onChange={handleOrderIdChange}
                    value={orderId}
                    style={{
                      backgroundColor: "#F9F8F8",
                    }}
                  />
                </Form.Item>

                {messages && (
                  <span
                    style={{
                      width: "322px",
                      height: "68px",
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "162.9%",
                      textAlign: "center",
                      color: "#FF0000",
                    }}
                  >
                    *{messages}
                  </span>
                )}
                <br />
                {isMessageVisible && (
                  <Link to="/find-orderId">
                    <span
                      style={{
                        width: "228px",
                        height: "33px",
                        fontFamily: "Nunito Sans",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "16px",
                        lineHeight: "162.9%",
                        textAlign: "center",
                        color: "#000000",
                        marginLeft: "10px",
                        textDecorationLine: "underline",
                      }}
                    >
                      {" "}
                      Can't find my order ID
                    </span>
                  </Link>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  position: "fixed",
                  justifyContent: "center",
                  alignContent: "center",
                  bottom: "0",
                  backgroundColor: "white",
                  zIndex: "50",
                }}
                className="footer"
              >
                <Button
                  style={{
                    width: "95%",
                    height: "45px",
                    backgroundColor: "#B7D340",
                    color: "#414042",
                    fontWeight: "600",
                    fontSize: "18px",
                    border: "1.0016px solid #E0E0E0",
                    borderRadius: "32px",
                    textAlign: "center",
                  }}
                  className="inputwidth"
                  htmlType="submit"
                  disabled={!phoneno || !orderId}
                >
                  Submit
                </Button>
              </div>
              <div>
                <p
                  className="subHeaderText"
                  style={{
                    padding: "0% 2.5%",
                  }}
                >
                  To get your prescription
                </p>
                <div
                  className="media-scroller-3 snaps-inline"
                  style={{
                    marginBottom: "3%",
                    padding: "5% 2%",
                    width: "95%",
                  }}
                >
                  <div className="media-element video-box">
                    <ReactPlayer
                      url={"https://www.youtube.com/watch?v=OpMlXI8UPGw"}
                      controls={true}
                      playing={true}
                      width="280px"
                      className="relative"
                      height="160px"
                      light={Presv1}
                      style={{
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  <div className="media-element video-box">
                    <ReactPlayer
                      url={"https://www.youtube.com/watch?v=Bb6ScRTJf50"}
                      controls={true}
                      playing={true}
                      width="280px"
                      className="relative"
                      height="160px"
                      light={Presv2}
                      style={{
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  paddingBottom: "18%",
                  margin: "0 auto",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    paddingLeft: "2.5%",
                  }}
                  className="faq"
                >
                  FAQ's:
                </p>
                {FAQContent.map((item) => {
                  return (
                    <>
                      <HomePageAccordian
                        title={item.title}
                        content={item.content}
                      />
                    </>
                  );
                })}
              </div>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;

const FAQContent = [
  {
    title: "How can I get my prescription?",
    content:
      "Once your monthly kit is delivered, you can access your digital prescription by scanning the code inside the box. You can also view your prescription by logging into the Traya app or website. Your prescription will have the details about the dosage and usage of each product in your kit.",
  },
  {
    title: "Who is a Traya hair coach and how can I connect with them?",
    content:
      "A Traya hair coach is like a bridge between you and the doctors, who's assigned to you to help you stay on track. The hair coach guides you throughout the treatment journey, takes updates from doctors to monitor your progress and follows up to ensure better results. You can book a call with your hair coach by scheduling a time slot after logging into the Traya app or website.",
  },
  {
    title: "How can I get my diet plan?",
    content:
      "Your customised diet plan is available on the app or can be accessed anytime by logging into the Traya website. Our nutritionist curates these healing diet plans to keep your hair internally nourished.",
  },
  {
    title: "When can I expect results?",
    content:
      "On average, people start seeing results with Traya's hair growth treatment in 5–6 months for the early stages of hair loss. The exact time taken to see these results also depends on factors like age, type, stage, and the reason behind your hair loss. Collectively, over 2.5 lakh Indians have chosen Traya. 93% of customers have seen results with Traya's treatment plans.",
  },
  {
    title: "Will Traya's treatment work on genetic cases of hairfall?",
    content:
      "In early stages, Traya's customised treatment plans can help manage your genetic hair loss and prevent it from developing into baldness, as the plans are carefully curated with our Holistic 3 Science Formula that blends dermatology, ayurveda and nutrition.",
  },
  {
    title:
      "I have a health/medical condition. Can I still take Traya's hair growth treatment?",
    content:
      "In most cases, hair loss is a result of an underlying condition. That is why Traya doctors go through each and every case before approving the treatment plan and then customise the dosage accordingly. However, please make sure you specify your condition to the experts before starting the treatment.",
  },
];
