import React from "react";
import { Drawer, Space } from "antd";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import ConstiClear from "../assets/Consti_Clear.png";
import DefenceShampoo from "../assets/Defence_Shampoo.png";
import DigestBoost from "../assets/Digest_Boost.png";
import GuttShuddhi from "../assets/Gutt_Shuddhi.png";
import HairVitamin from "../assets/Hair_Vitamin.png";
import HairRas from "../assets/Hair_Ras.png";
import HealthTatva from "../assets/Health_Tatva.png";
import DandruffShampoo from "../assets/Ketoconazole_Shampoo.png";
import Minoxidil_2 from "../assets/Minoxidil_2.png";
import Minoxidil_5 from "../assets/Minoxidil_5.png";
import NasalGhrit from "../assets/Nasal_Ghrit.png";
import RecapSerum from "../assets/Recap_Hair_Serum.png";
import ScalpController from "../assets/Scalp_Controller.png";
import ScalpOil from "../assets/Scalp_Oil.png";
import ScalpOilCalmTherapy from "../assets/Scalp_Oil_with_Calm_Therapy_Booster_Shots.png";
import ScalpOilDandruffTherapy from "../assets/Scalp_Oil_with_Dandruff_Therapy_Booster_Shots.png";
import ScalpOilGrowthTherapy from "../assets/Scalp_Oil_with_Growth_Therapy_Booster_Shots.png";
import ScalpOilScalpTherapy from "../assets/Scalp_Oil_with_Scalp_Therapy_Booster_Shots.png";
import Ketoconazole from "../assets/Ketoconazole_Night_Lotion.png";
import KevonLotion from "../assets/Kevon_Lotion_2.png";
import CholestVati from "../assets/Cholest_Vati.png";
import MENOSANTULAN from "../assets/MENO_SANTULAN.png";
import MOMSANTULAN from "../assets/MOM_SANTULAN.png";
import PCOSSANTULAN from "../assets/PCOS_SANTULAN.png";
import THYROSANTULAN from "../assets/THYRO_SANTULAN.png";
import Gutt from "../assets/Gutt_Shudhi_t.png";
import Health from "../assets/Health_Tatva_t.png";
import Hair from "../assets/Hair_Ras_t.png";
import Anti from "../assets/Anti-Dandruff_Shampoo_t.png";
import Cholest from "../assets/Cholest_vati_t.png";
import Consti from "../assets/Consti_Clear_t.png";
import Defence from "../assets/Defence_Shampoo_t.png";
import Digest from "../assets/Digest_Boost_t.png";
import HairV from "../assets/Hair_Vitamin_t.png";
import Keto from "../assets/Ketoconazole_Night_Lotion_t.png";
import Meno from "../assets/Meno_Santulan_t.png";
import Mino_2 from "../assets/Minoxidil_2_t.png";
import Mino_5 from "../assets/Minoxidil_5_t.png";
import Mom from "../assets/Mom_Santulan_t.png";
import Nasal from "../assets/Nasal_ghrit_t.png";
import PCOS from "../assets/PCOS_Santulan_t.png";
import Recap from "../assets/Recap_Serum_t.png";
import ScalpC from "../assets/Scalp_Calm_Booster_t.png";
import ScalpD from "../assets/Scalp_Dandruff_Booster_t.png";
import ScalpG from "../assets/Scalp_Growth_Booster_t.png";
import ScalpS from "../assets/Scalp_Scalp_Booster_t.png";
import Scalp from "../assets/Scalp_Oil_t.png";
import Thyro from "../assets/Thyro_Santulan_t.png";
import Hydrate_Shampoo_Banner from "../assets/Hydrate_Shampoo_t.png";
import Hydrate_Conditioner_Banner from "../assets/Hydrate_Conditioner_t.png";
import Hydrate_Shampoo from "../assets/Hydrate_Shampoo.png";
import Hydrate_Conditioner from "../assets/Hydrate_Conditioner.png";
import FAQComponent from "./FAQComponent";

const MedicineDrawer = ({
  open,
  onClose,
  medicine,
  toggleButton,
  videoLink,
  setVideoLink,
  visible,
  setVisible,
  medicineType,
  gender,
}) => {
  return (
    <>
      <Drawer
        placement={isMobile ? "bottom" : "right"}
        width={"25%"}
        height={"90%"}
        onClose={() => {
          setTimeout(() => {
            onClose();
            setVideoLink("");
          }, 100);
          setVisible(false);
        }}
        closeIcon={null}
        open={open}
        extra={
          <Space>
            <button
              style={{
                padding: "10% 15%",
                fontSize: "18px",
                borderRadius: "2px",
                border: "none",
                fontFamily: "Nunito Sans",
                fontStyle: "normal",
                fontWeight: "500",
                color: gender === "F" ? "white" : "#414042",
                background: gender === "F" ? "#D88673" : "#B7D340",
                boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.08)",
                cursor: "pointer",
              }}
              type="primary"
              onClick={() => {
                setTimeout(() => {
                  onClose();
                  setVideoLink("");
                }, 100);
                setVisible(false);
              }}
            >
              Close
            </button>
          </Space>
        }
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            minHeight: "100px",
            margin: "5% 0% 2% 0%",
            paddingBottom: "2%",
          }}
        >
          <div className="imgContainer" style={{ width: "35%" }}>
            <div
              style={{
                backgroundColor: "#F9F8F8",
                width: "90%",
                height: "105px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
            >
              <img
                src={getMedicineImage[medicine]}
                width="auto"
                height="100px"
                alt="Medicine Image"
                style={{
                  paddingLeft: "25%",
                  paddingRight: "25%",
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: "65%",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <span className="medicine-1">{getMedicineName[medicine]}</span>

              {medicine !== "Damage Repair Conditioner" &&
                medicine !== "Hydrate Damage Repair Shampoo" && (
                  <div
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: gender === "F" ? "#D88673" : "#B7D340",
                        padding: "1px 10px",
                        borderRadius: "2px",
                        fontFamily: "Nunito Sans",
                        fontStyle: "normal",
                        fontSize: "16px",
                      }}
                    >
                      <span style={{ color: "#ffffff" }}>
                        {getMedicineType[medicineType]}
                      </span>
                    </span>
                  </div>
                )}
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <span
                  className="medicine-2"
                  style={{
                    fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                  }}
                >
                  <span>
                    {toggleButton
                      ? getMedicineDosage[medicine]
                      : getMedicineDosageHindi[medicine]}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        {medicine !== "Damage Repair Conditioner" &&
          medicine !== "Hydrate Damage Repair Shampoo" && (
            <div>
              <p
                style={{
                  fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "18px",
                  lineHeight: "23px",
                  color: "#414042",
                }}
              >
                {toggleButton
                  ? getMedicineGuide[medicine]
                  : getMedicineGuideHindi[medicine]}
              </p>
            </div>
          )}
        {(getDoctorNote[medicine] !== "" ||
          getDoctorNoteHindi[medicine] !== "") &&
          medicine !== "Damage Repair Conditioner" &&
          medicine !== "Hydrate Damage Repair Shampoo" && (
            <div>
              <span
                style={{
                  fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#BABABA",
                }}
              >
                {toggleButton ? "Doctor’s note :" : "डॉक्टर का नोट :"}
              </span>
              <div
                style={{
                  marginTop: "5px",
                }}
              >
                <p
                  style={{
                    fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "16px",
                    lineHeight: "21px",
                    color: "#414042",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {toggleButton
                    ? getDoctorNote[medicine]
                    : getDoctorNoteHindi[medicine]}
                </p>
              </div>
            </div>
          )}
        {medicine === "Hydrate Damage Repair Shampoo" && (
          <div
            style={{
              width: "100%",
              marginTop: "5%",
            }}
          >
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                color: "#414042",
              }}
            >
              {toggleButton ? "1. Preparation" : "1. तैयारी"}
            </p>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414042",
                paddingLeft: "5%",
              }}
            >
              {toggleButton
                ? "Start by wetting your hair thoroughly with water. Make sure your hair is completely wet."
                : "अपने बालों को पानी से अच्छी तरह गीला कर लें।सुनिश्चित करें कि आपके बाल पूरी तरह गीले हैं।"}
            </p>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                color: "#414042",
              }}
            >
              {toggleButton ? "2. Shampooing" : "2. शैंपू"}
            </p>

            <ul
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414042",
                paddingLeft: "8%",
                listStyleType: "disc",
              }}
            >
              <li>
                {toggleButton
                  ? "Squeeze a moderate amount of Hydrating Shampoo into your palm."
                  : "अपने हाथ में थोड़ा सा हाइड्रेटिंग शैम्पू लें।"}
              </li>
              <li>
                {toggleButton
                  ? "Gently massage the shampoo for 2-3 minutes into your scalp using your fingertips"
                  : "अपनी उंगलियों का उपयोग करके, शैम्पू को अपने स्कैल्प पर 2-3 मिनट तक धीरे-धीरे मालिश करें।"}
              </li>
            </ul>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                color: "#414042",
              }}
            >
              {toggleButton ? "3. Rinse" : "3. धोना"}
            </p>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414042",
                paddingLeft: "5%",
              }}
            >
              {toggleButton
                ? "Thoroughly rinse your hair with water and ensure your hair feels squeaky clean."
                : "अब, अपने बालों को पानी से धो लें, यह सुनिश्चित कर लें कि आपके बाल अच्छी तरह से साफ हो गए हैं।"}
            </p>
          </div>
        )}
        {medicine === "Damage Repair Conditioner" && (
          <div
            style={{
              width: "100%",
              marginTop: "5%",
            }}
          >
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                color: "#414042",
              }}
            >
              {toggleButton ? "1. Rinse" : "1. धोना"}
            </p>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414042",
                paddingLeft: "5%",
              }}
            >
              {toggleButton
                ? "Thoroughly rinse your hair with water and ensure your hair feels squeaky clean."
                : "अब, अपने बालों को पानी से धो लें, यह सुनिश्चित कर लें कि आपके बाल अच्छी तरह से साफ हो गए हैं।"}
            </p>
            <p
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "16px",
                color: "#414042",
              }}
            >
              {toggleButton ? "2. Conditioning" : "2. कंडीशनिंग"}
            </p>

            <ul
              style={{
                fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414042",
                paddingLeft: "8%",
                listStyleType: "disc",
              }}
            >
              <li>
                {toggleButton
                  ? "Apply Hydrating Conditioner only to the lengths and ends of your hair and leave it on for 1-2 minutes."
                  : "हाइड्रेटिंग कंडीशनर को केवल अपने बालों की लंबाई पर लगाएं और 1-2 मिनट के लिए छोड़ दें।"}
              </li>

              <li>
                {toggleButton
                  ? "Rinse the conditioner out with cool water to lock in moisture and seal the cuticles."
                  : "फिर अपने बालों में नमी बनाए रखने और क्यूटिकल्स को सील करने के लिए कंडीशनर को ठंडे पानी से धो लें।"}
              </li>
            </ul>
          </div>
        )}

        <div className="w-full mt-[5%]">
          <p
            style={{
              fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "16px",
              lineHeight: "21px",
              color: "#BABABA",
            }}
          >
            {toggleButton ? "How to use?" : "उपयोग कैसे करें?"}
          </p>
          <ReactPlayer
            url={videoLink}
            id="video"
            controls={true}
            playing={visible}
            width="100%"
            height="200px"
            light={visible ? getVideoBanner[medicine] : false}
            style={{
              borderRadius: "4px",
            }}
          />
        </div>
        {medicine !== "Damage Repair Conditioner" &&
          medicine !== "Hydrate Damage Repair Shampoo" && (
            <div className="mt-2">
              <span
                style={{
                  fontFamily: toggleButton ? "Nunito Sans" : "Mukta",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color: "#BABABA",
                }}
              >
                {toggleButton ? "FAQ’s" : "अक्सर पूछे जाने वाले प्रश्न (FAQ)"}
              </span>
              <FAQComponent
                medicine={medicine}
                toggleButton={toggleButton}
                gender={gender}
              />
            </div>
          )}
      </Drawer>
    </>
  );
};
export default MedicineDrawer;

const getMedicineName = {
  "Health Tatva": "Health Tatva",
  "Hair Ras": "Hair Ras",
  "Hair Vitamin": "Hair Vitamin",
  "Defence shampoo": "Defence Shampoo",
  "Consti Clear for Improved Bowel Movement": "Consti Clear",
  "Digest Boost for Improved Digestive Ability": "Digest Boost",
  "Gutt Shuddhi": "Gutt Shuddhi",
  "Anti-Dandruff Shampoo": "Anti-Dandruff Shampoo",
  "Minoxidil 5%": "Minoxidil 5%",
  "Minoxidil 2%": "Minoxidil 2%",
  "Nasal Drops": "Nasal Drops",
  "Scalp Oil": "Scalp Oil",
  "Scalp Oil + Calm Therapy Booster": "Scalp Oil + Calm Therapy Booster",
  "Scalp Oil + Calm Oil Shot": "Scalp Oil + Calm Oil Shot",
  "Calm Therapy Booster Shots": "Calm Therapy Booster Shots",
  "Calm Oil Shot": "Calm Oil Shot",
  "Scalp Oil + Dandruff Therapy Booster":
    "Scalp Oil + Dandruff Therapy Booster",
  "Dandruff Therapy Booster Shots": "Dandruff Therapy Booster Shots",
  "Scalp Oil + Growth Oil Shot": "Scalp Oil + Growth Oil Shot",
  "Growth Oil Shot": "Growth Oil Shot",
  "Scalp Oil + Scalp Therapy Booster": "Scalp Oil + Scalp Therapy Booster",
  "Scalp Therapy Booster Shots": "Scalp Therapy Booster Shots",
  "Scalp Controller": "Scalp Controller",
  "Recap Serum": "Recap Serum",
  "Ketoconazole 2% Night Lotion": "Ketoconazole 2% Night Lotion",
  "Kevon Lotion": "Kevon Lotion",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Cholest Vati",
  "Meno Santulan": "Meno Santulan",
  "Mom Santulan": "Mom Santulan",
  "PCOS Santulan": "PCOS Santulan",
  "Thyro Santulan": "Thyro Santulan",
  "Damage Repair Conditioner": "Hydrating Conditioner",
  "Hydrate Damage Repair Shampoo": "Hydrating Shampoo",
};

const getMedicineGuide = {
  "Health Tatva":
    "Take 1 tablet of  Health Tatva after breakfast, 1 tablet of  Health Tatva after dinner everyday with a glass of water.",
  "Hair Ras":
    "Take 2 tablets of Hair Ras after breakfast, 2 tablets of Hair Ras after dinner everyday with a glass of water.",
  "Hair Vitamin":
    "Take 1 tablet of Hair Vitamin after breakfast everyday with a glass of water.",
  "Defence shampoo":
    "Take 2-3 pumps and lather well into your scalp. Massage using your hands for 2-3 minutes and rinse off with water.",
  "Consti Clear for Improved Bowel Movement":
    "Take 2 tablets of Consti Clear after dinner with a glass of water.",
  "Digest Boost for Improved Digestive Ability":
    "Take 1 tablet of Digest Boost after breakfast, 1 tablet of Digest Boost after dinner everyday with a glass of water.",
  "Gutt Shuddhi":
    "Take 1 tablet of Gutt Shuddhi after dinner everyday with a glass of water.",
  "Anti-Dandruff Shampoo":
    "Take enough amount and lather well into your scalp. Massage using your hands for 3 minutes and rinse off with water.",
  "Minoxidil 5%":
    "Use the dropper to apply on a clean and dry scalp. Do not let it drip down your face, apply only on affected areas.",
  "Minoxidil 2%":
    "Use the dropper to apply on a clean and dry scalp. Do not let it drip down your face, apply only on affected areas.",
  "Nasal Drops":
    "Put 3 drops in each nostril in laying down position. Insert the bottle in warm water before use.",
  "Scalp Oil": "Use twice a week",
  "Scalp Oil + Calm Therapy Booster":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Calm Therapy Booster Shots":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Dandruff Therapy Booster":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Dandruff Therapy Booster Shots":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Growth Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Growth Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Scalp Therapy Booster": "Use twice a week",
  "Scalp Therapy Booster Shots": "Use twice a week",
  "Scalp Controller": "",
  "Recap Serum":
    "Use the dropper to apply on a clean and dry scalp on areas where you see hair loss or thinning.",
  "Ketoconazole 2% Night Lotion":
    "Take a small amount and apply a thin layer over the scalp before bedtime.",
  "Kevon Lotion":
    "Take a small amount and apply a thin layer over the scalp before bedtime.",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Take 2 tablets of Cholest Vati after breakfast, 2 tablets of Cholest Vati after dinner everyday with a glass of water.",
  "Meno Santulan":
    "Take 2 tablets after breakfast, 1 after dinner with a glass of water.",
  "Mom Santulan":
    "Take 2 tablets of Meno Santulan after breakfast, 2 tablets of Meno Santulan after dinner everyday with a glass of water.",
  "PCOS Santulan":
    "Take 2 tablets of PCOS Santulan after breakfast, 2 tablets of PCOS Santulan after dinner everyday with a glass of water.",
  "Thyro Santulan":
    "Take 2 tablets of Thyro Santulan after breakfast, 2 tablets of Thyro Santulan after dinner everyday with a glass of water.",
  "Damage Repair Conditioner":
    "Thoroughly rinse your hair with water and ensure your hair feels squeaky clean.",
  "Hydrate Damage Repair Shampoo":
    "Start by wetting your hair thoroughly with water. Make sure your hair is completely wet.",
};

const getMedicineGuideHindi = {
  "Health Tatva":
    "प्रतिदिन नाश्ते के बाद 1 हेल्थ तत्वा की गोली लें, रात के खाने के बाद भी 1 हेल्थ तत्वा की गोली, एक ग्लास पानी के साथ लें।",
  "Hair Ras":
    "नाश्ते के बाद दो टेबलेट लें, रात के खाने के बाद एक ग्लास पानी के साथ दो टेबलेट लें।",
  "Hair Vitamin":
    "प्रतिदिन सुबह नाश्ते के बाद एक ग्लास पानी के साथ एक टेबलेट लें।",
  "Defence shampoo":
    "2-3 पंप लें और अपने स्कैल्प में अच्छी तरह से फोम बनाएँ। 2-3 मिनट तक अपने हाथों से मालिश करें और पानी से धो दें।",
  "Consti Clear for Improved Bowel Movement":
    "२ टेबलेट रात के खाने के बाद पानी के साथ खा लें।",
  "Digest Boost for Improved Digestive Ability":
    "प्रतिदिन नाश्ते के बाद 1 डाइजेस्ट बूस्ट की गोली लें, रात के खाने के बाद भी 1 डाइजेस्ट बूस्ट की गोली, एक ग्लास पानी के साथ लें।",
  "Gutt Shuddhi":
    "प्रतिदिन रात के खाने के बाद एक ग्लास पानी के साथ एक टेबलेट लें।",
  "Anti-Dandruff Shampoo":
    "ना बहुत कम, ना बहुत ज्यादा मात्रा में शैम्पू लें और सर पर अच्छी तरह से लगायें। ३ मिनट तक मालिश करें और फिर अच्छी तरह से धो लें।",
  "Minoxidil 5%":
    "साफ और सूखे स्कैल्प पर ड्रॉपर का उपयोग करें। इसे अपने चेहरे पर टपकने नहीं दें, केवल प्रभावित क्षेत्रों पर लागू करें।",
  "Minoxidil 2%":
    "साफ और सूखे स्कैल्प पर ड्रॉपर का उपयोग करें। इसे अपने चेहरे पर टपकने नहीं दें, केवल प्रभावित क्षेत्रों पर लागू करें।",
  "Nasal Drops":
    "लेटते हुए स्थिति में प्रत्येक नाक में 3 बूंद डालें। उपयोग से पहले बोतल को गरम पानी में डालें।",
  "Scalp Oil": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Calm Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Calm Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Calm Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Dandruff Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Dandruff Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Growth Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Growth Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Scalp Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Controller": "",
  "Recap Serum":
    "ड्रॉपर का उपयोग करके, साफ और सूखे स्कैल्प पर लागू करें जहाँ आप बालों का झड़ना या कमजोर होने का अनुभव कर रहे हो।",
  "Ketoconazole 2% Night Lotion":
    "सोने से पहले थोड़ी सी मात्रा लें और स्कैल्प पर एक पतली परत लगाएँ।",
  "Kevon Lotion": "सोने से पहले थोड़ा सा लें और स्कैल्प पर एक पतली परत लगाएं।",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "प्रतिदिन नाश्ते के बाद 2 चोलेस्ट वटी की गोलियां लें, रात के खाने के बाद भी 2 गोलियां, पानी के एक ग्लास पानी के साथ लें।",
  "Meno Santulan":
    "प्रतिदिन नाश्ते के बाद 2 मेनो संतुलन की गोलियां लें, रात के खाने के बाद भी 2 मेनो संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "Mom Santulan":
    "प्रतिदिन नाश्ते के बाद 2 मोम संतुलन की गोलियां लें, रात के खाने के बाद भी 2 मोम संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "PCOS Santulan":
    "प्रतिदिन नाश्ते के बाद 2 पीसीओएस संतुलन की गोलियां लें, रात के खाने के बाद भी 2 पीसीओएस संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "Thyro Santulan":
    "नाश्ते के बाद दो टेबलेट लें, रात के खाने के बाद एक ग्लास पानी के साथ दो टेबलेट लें।",
  "Damage Repair Conditioner":
    "अब, अपने बालों को पानी से धो लें, यह सुनिश्चित कर लें कि आपके बाल अच्छी तरह से साफ हो गए हैं।",
  "Hydrate Damage Repair Shampoo":
    "अपने बालों को पानी से अच्छी तरह गीला कर लें।सुनिश्चित करें कि आपके बाल पूरी तरह गीले हैं।",
};

const getMedicineDosage = {
  "Health Tatva": "One tablet twice a day",
  "Hair Ras": "Two tablets twice a day",
  "Hair Vitamin": "Take one tablet in the morning",
  "Defence shampoo": "Use twice a week",
  "Consti Clear for Improved Bowel Movement": "Take two tablets at night",
  "Digest Boost for Improved Digestive Ability": "One tablet twice a day",
  "Gutt Shuddhi": "Take one tablet at night",
  "Anti-Dandruff Shampoo": "Use twice a week",
  "Minoxidil 5%": "Use 1ml, twice a day",
  "Minoxidil 2%": "Use 1ml, twice a day",
  "Nasal Drops": "Use daily before bedtime",
  "Scalp Oil": "Use twice a week",
  "Scalp Oil + Calm Therapy Booster": "Use twice a week",
  "Scalp Oil + Calm Oil Shot": "Use twice a week",
  "Calm Therapy Booster Shots": "Use twice a week",
  "Calm Oil Shot": "Use twice a week",
  "Scalp Oil + Dandruff Therapy Booster": "Use twice a week",
  "Dandruff Therapy Booster Shots": "Use twice a week",
  "Scalp Oil + Growth Oil Shot": "Use twice a week",
  "Growth Oil Shot": "Use twice a week",
  "Scalp Oil + Scalp Therapy Booster": "Use twice a week",
  "Scalp Therapy Booster Shots": "Use twice a week",
  "Scalp Controller": "",
  "Recap Serum": "Apply daily at night",
  "Ketoconazole 2% Night Lotion": "Apply on affected areas at night",
  "Kevon Lotion": "Apply on affected areas at night",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Two tablets twice a day",
  "Meno Santulan": "Two tablets twice a day",
  "Mom Santulan": "Two tablets twice a day",
  "PCOS Santulan": "Two tablets twice a day",
  "Thyro Santulan": "Take two tablets twice a day",
  "Damage Repair Conditioner": "Use as required",
  "Hydrate Damage Repair Shampoo": "Use as required",
};

const getMedicineDosageHindi = {
  "Health Tatva": "दिन में दो बार एक टैबलेट लें।",
  "Hair Ras": "दिन में दो बार दो टेबलेट लें।",
  "Hair Vitamin": "सुबह एक टेबलेट लें।",
  "Defence shampoo": "हफ्ते में दो बार इस्तेमाल करें।",
  "Consti Clear for Improved Bowel Movement": "रात में दो टैबलेट लें।",
  "Digest Boost for Improved Digestive Ability":
    "दिन में दो बार एक टैबलेट लें।",
  "Gutt Shuddhi": "रात में एक टेबलेट लें।",
  "Anti-Dandruff Shampoo": "हफ्ते में दो बार इस्तेमाल करें।",
  "Minoxidil 5%": "1 मिलीलीटर, दिन में दो बार उपयोग करें।",
  "Minoxidil 2%": "1 मिलीलीटर, दिन में दो बार उपयोग करें।",
  "Nasal Drops": "रोज सोने से पहले इस्तेमाल करें।",
  "Scalp Oil": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Calm Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Calm Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Dandruff Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Dandruff Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Growth Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Growth Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Scalp Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Controller": "",
  "Recap Serum": "रोज रात में लगाएं।",
  "Ketoconazole 2% Night Lotion": "रात में प्रभावित क्षेत्रों पर लगाएँ।",
  "Kevon Lotion": "आफ़ेक्टेड एरियों पर रात में लगाएँ।",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "दिन में दो बार दो टैबलेट लें।",
  "Meno Santulan": "दिन में दो बार दो गोलियां लें।",
  "Mom Santulan": "दिन में दो बार दो गोलियां लें।",
  "PCOS Santulan": "दिन में दो बार दो गोलियां लें।",
  "Thyro Santulan": "दिन में दो बार दो टेबलेट लें।",
  "Damage Repair Conditioner": "आवश्यकता अनुसार उपयोग करें",
  "Hydrate Damage Repair Shampoo": "आवश्यकता अनुसार उपयोग करें",
};

const getDoctorNote = {
  "Health Tatva": "Health Tatva can be taken along with other Traya medicines.",
  "Hair Ras": "Hair Ras can be taken along with other Traya medicines.",
  "Hair Vitamin": "Do not combine with your current vitamin course.",
  "Defence shampoo":
    "For an oily scalp- use thrice a week.\nFor scalp is too dry- use once a week.\nFor normal scalp- use twice a week",
  "Consti Clear for Improved Bowel Movement":
    "Consti Clear can be taken along with other Traya medicines.",
  "Digest Boost for Improved Digestive Ability":
    "Digest Boost can be taken along with other Traya medicines.",
  "Gutt Shuddhi": "Gutt Shuddhi can be taken along with other Traya medicines.",
  "Anti-Dandruff Shampoo": "",
  "Minoxidil 5%": "You might notice white residue after using.",
  "Minoxidil 2%": "You might notice white residue after using.",
  "Nasal Drops": "Contains ghee which may solidify.",
  "Scalp Oil": "Use twice a week",
  "Scalp Oil + Calm Therapy Booster":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes. ",
  "Scalp Oil + Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes. ",
  "Calm Therapy Booster Shots":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes. ",
  "Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes. ",
  "Scalp Oil + Dandruff Therapy Booster":
    "Leave for 30-40 mins & wash off immediately",
  "Dandruff Therapy Booster Shots":
    "Leave for 30-40 mins & wash off immediately",
  "Scalp Oil + Growth Oil Shot":
    "Leave for 1-2 hrs in cases of mild dandruff & wash immediately",
  "Growth Oil Shot":
    "Leave for 1-2 hrs in cases of mild dandruff & wash immediately",
  "Scalp Oil + Scalp Therapy Booster": "Use twice a week",
  "Scalp Therapy Booster Shots": "Use twice a week",
  "Scalp Controller": "",
  "Recap Serum": "Do not wash it immediately.",
  "Ketoconazole 2% Night Lotion":
    "Do not apply Scalp oil & night lotion together.",
  "Kevon Lotion": "Do not apply Scalp oil & night lotion together.",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Cholest Vati can be taken with other Cholesterol medications",
  "Meno Santulan":
    "You can continue using Meno Santulan along with your existing medicines, if any.",
  "Mom Santulan":
    "You can continue using Mom Santulan along with your existing medicines, if any.",
  "PCOS Santulan":
    "PCOS Santulan can be taken with other PCOS medications, if any.",
  "Thyro Santulan":
    "Thyro Santulan can be taken along with your existing Thyroid medicines.",
};

const getDoctorNoteHindi = {
  "Health Tatva": "आप सभी टेबलेट एक साथ खा सकते हैं।",
  "Hair Ras": "आप अपनी सभी गोलियों को एक साथ ले सकते हैं।",
  "Hair Vitamin": "अपनी वर्तमान विटामिन कोर्स के साथ मिलाकर न लें।",
  "Defence shampoo":
    "तेलीय स्कैल्प के लिए - हफ्ते में तीन (३) बार इस्तेमाल करें.\nबहुत रूखे स्कैल्प के लिए - हफ्ते में एक (१) बार इस्तेमाल करें.\nसामान्य स्कैल्प के लिए - हफ्ते में दो (२) बार इस्तेमाल करें",
  "Consti Clear for Improved Bowel Movement":
    "आप सभी टेबलेट एक साथ खा सकते हैं।",
  "Digest Boost for Improved Digestive Ability":
    "आप सभी टेबलेट एक साथ खा सकते हैं।",
  "Gutt Shuddhi": "आप अपनी सभी गोलियों को एक साथ ले सकते हैं।",
  "Anti-Dandruff Shampoo": "",
  "Minoxidil 5%": "आपको उपयोग करने के बाद सफेद अवशेष दिखाई दे सकते हैं।",
  "Minoxidil 2%": "आपको उपयोग करने के बाद सफेद अवशेष दिखाई दे सकते हैं।",
  "Nasal Drops": "घी शामिल है जो जम सकता है।",
  "Scalp Oil": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Therapy Booster":
    "5 मिनट तक अपने स्कैल्प में हल्के हाथों से मालिश करें।",
  "Scalp Oil + Calm Oil Shot":
    "5 मिनट तक अपने स्कैल्प में हल्के हाथों से मालिश करें।",
  "Calm Therapy Booster Shots":
    "5 मिनट तक अपने स्कैल्प में हल्के हाथों से मालिश करें।",
  "Calm Oil Shot": "5 मिनट तक अपने स्कैल्प में हल्के हाथों से मालिश करें।",
  "Scalp Oil + Dandruff Therapy Booster":
    "३०-४० मिनट तक छोड़ें और तुरंत बाल धो लें।",
  "Dandruff Therapy Booster Shots": "३०-४० मिनट तक छोड़ें और तुरंत बाल धो लें।",
  "Scalp Oil + Growth Oil Shot":
    "हल्के डैंड्रफ के मामलों में १-२ घंटे तक छोड़ें और फिर तुरंत बाल धो लें।",
  "Growth Oil Shot":
    "हल्के डैंड्रफ के मामलों में १-२ घंटे तक छोड़ें और फिर तुरंत बाल धो लें।",
  "Scalp Oil + Scalp Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Controller": "",
  "Recap Serum": "तुरंत बाल ना धोएं।",
  "Ketoconazole 2% Night Lotion":
    "स्कैल्प तेल और नाईट लोशन को साथ में नहीं लगाएं।",
  "Kevon Lotion": "स्कैल्प तेल और नाईट लोशन को साथ में नहीं लगाएं।",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "आप यह टेबलेट अपने दूसरे टेबलेट के साथ खा सकते हैं।",
  "Meno Santulan": "आप अपनी मौजूदा दवाओं को जारी रख सकते हैं।",
  "Mom Santulan":
    "यदि कोई हो तो आप मोम संतुलन का उपयोग अपनी मौजूदा दवाओं के साथ जारी रख सकते हैं।",
  "PCOS Santulan":
    "PCOS Santulan को किसी भी अन्य PCOS दवाओं के साथ लिया जा सकता है, यदि कोई हो।",
  "Thyro Santulan": "आप अपनी मौजूदा थायरॉयड दवाओं को जारी रख सकते हैं।",
};

const getMedicineType = {
  allopathy: "Dermatology",
  ayurveda: "Ayurveda",
  combo: "Ayurveda",
};

const getMedicineImage = {
  "Health Tatva": HealthTatva,
  "Hair Ras": HairRas,
  "Hair Vitamin": HairVitamin,
  "Defence shampoo": DefenceShampoo,
  "Consti Clear for Improved Bowel Movement": ConstiClear,
  "Digest Boost for Improved Digestive Ability": DigestBoost,
  "Gutt Shuddhi": GuttShuddhi,
  "Anti-Dandruff Shampoo": DandruffShampoo,
  "Minoxidil 5%": Minoxidil_5,
  "Minoxidil 2%": Minoxidil_2,
  "Nasal Drops": NasalGhrit,
  "Scalp Oil": ScalpOil,
  "Scalp Oil + Calm Therapy Booster": ScalpOilCalmTherapy,
  "Scalp Oil + Calm Oil Shot": ScalpOilCalmTherapy,
  "Calm Therapy Booster Shots": ScalpOilCalmTherapy,
  "Calm Oil Shot": ScalpOilCalmTherapy,
  "Scalp Oil + Dandruff Therapy Booster": ScalpOilDandruffTherapy,
  "Dandruff Therapy Booster Shots": ScalpOilDandruffTherapy,
  "Scalp Oil + Growth Oil Shot": ScalpOilGrowthTherapy,
  "Growth Oil Shot": ScalpOilGrowthTherapy,
  "Scalp Oil + Scalp Therapy Booster": ScalpOilScalpTherapy,
  "Scalp Therapy Booster Shots": ScalpOilScalpTherapy,
  "Scalp Controller": ScalpController,
  "Recap Serum": RecapSerum,
  "Ketoconazole 2% Night Lotion": Ketoconazole,
  "Kevon Lotion": KevonLotion,
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol": CholestVati,
  "Meno Santulan": MENOSANTULAN,
  "Mom Santulan": MOMSANTULAN,
  "PCOS Santulan": PCOSSANTULAN,
  "Thyro Santulan": THYROSANTULAN,
  "Damage Repair Conditioner": Hydrate_Conditioner,
  "Hydrate Damage Repair Shampoo": Hydrate_Shampoo,
};

const getVideoBanner = {
  "Health Tatva": Health,
  "Hair Ras": Hair,
  "Hair Vitamin": HairV,
  "Defence shampoo": Defence,
  "Consti Clear for Improved Bowel Movement": Consti,
  "Digest Boost for Improved Digestive Ability": Digest,
  "Gutt Shuddhi": Gutt,
  "Anti-Dandruff Shampoo": Anti,
  "Minoxidil 5%": Mino_5,
  "Minoxidil 2%": Mino_2,
  "Nasal Drops": Nasal,
  "Scalp Oil": Scalp,
  "Scalp Oil + Calm Therapy Booster": ScalpC,
  "Scalp Oil + Calm Oil Shot": ScalpC,
  "Calm Therapy Booster Shots": ScalpC,
  "Calm Oil Shot": ScalpC,
  "Scalp Oil + Dandruff Therapy Booster": ScalpD,
  "Dandruff Therapy Booster Shots": ScalpD,
  "Scalp Oil + Growth Oil Shot": ScalpG,
  "Growth Oil Shot": ScalpG,
  "Scalp Oil + Scalp Therapy Booster": ScalpS,
  "Scalp Therapy Booster Shots": ScalpS,
  "Scalp Controller": "",
  "Recap Serum": Recap,
  "Ketoconazole 2% Night Lotion": Keto,
  "Kevon Lotion": Keto,
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol": Cholest,
  "Meno Santulan": Meno,
  "Mom Santulan": Mom,
  "PCOS Santulan": PCOS,
  "Thyro Santulan": Thyro,
  "Damage Repair Conditioner": Hydrate_Conditioner_Banner,
  "Hydrate Damage Repair Shampoo": Hydrate_Shampoo_Banner,
};
