import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ConstiClear from "../../assets/Consti_Clear.png";
import DefenceShampoo from "../../assets/Defence_Shampoo.png";
import DigestBoost from "../../assets/Digest_Boost.png";
import GuttShuddhi from "../../assets/Gutt_Shuddhi.png";
import HairVitamin from "../../assets/Hair_Vitamin.png";
import HairRas from "../../assets/Hair_Ras.png";
import HealthTatva from "../../assets/Health_Tatva.png";
import DandruffShampoo from "../../assets/Ketoconazole_Shampoo.png";
import Minoxidil_2 from "../../assets/Minoxidil_2.png";
import Minoxidil_5 from "../../assets/Minoxidil_5.png";
import NasalGhrit from "../../assets/Nasal_Ghrit.png";
import RecapSerum from "../../assets/Recap_Hair_Serum.png";
import ScalpController from "../../assets/Scalp_Controller.png";
import ScalpOil from "../../assets/Scalp_Oil.png";
import ScalpOilCalmTherapy from "../../assets/Scalp_Oil_with_Calm_Therapy_Booster_Shots.png";
import ScalpOilDandruffTherapy from "../../assets/Scalp_Oil_with_Dandruff_Therapy_Booster_Shots.png";
import ScalpOilGrowthTherapy from "../../assets/Scalp_Oil_with_Growth_Therapy_Booster_Shots.png";
import ScalpOilScalpTherapy from "../../assets/Scalp_Oil_with_Scalp_Therapy_Booster_Shots.png";
import Ketoconazole from "../../assets/Ketoconazole_Night_Lotion.png";
import KevonLotion from "../../assets/Kevon_Lotion_2.png";
import CholestVati from "../../assets/Cholest_Vati.png";
import MENOSANTULAN from "../../assets/MENO_SANTULAN.png";
import MOMSANTULAN from "../../assets/MOM_SANTULAN.png";
import PCOSSANTULAN from "../../assets/PCOS_SANTULAN.png";
import THYROSANTULAN from "../../assets/THYRO_SANTULAN.png";
import Footer from "../../assets/footer_common.png";
import Footer1 from "../../assets/footer_1.png";
import Footer2 from "../../assets/footer_2.png";
import Footer3 from "../../assets/footer_3.png";
import Footer4 from "../../assets/footer_4.png";
import Footer5 from "../../assets/footer_5.png";
import Footer6 from "../../assets/footer_6.png";
import PlayButton from "../../assets/play_button.png";
import Hydrate_Shampoo from "../../assets/Hydrate_Shampoo.png";
import Hydrate_Conditioner from "../../assets/Hydrate_Conditioner.png";
import HairCoachBanner from "../../assets/hairCoach.png";
import DietPlanBanner from "../../assets/diet_plan.jpg";
import PlayStore from "../../assets/play_store.png";
import AppStore from "../../assets/app_store.png";
import jsPdf from "jspdf";
import { getUserPrescription } from "./service";
import MedicineDrawer from "../../components/MedicineDrawer";
import VideoModal from "../../components/VideoModal";
import { CDN_BASE_URL } from "../../helpers/api-config";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

export default function Prescription() {
  const [xLinkCords, setXLinkCords] = useState(null);
  const [yLinkCords, setYLinkCords] = useState(null);
  const [xWatCords, setXWatCords] = useState(null);
  const [yWatCords, setYWatCords] = useState(null);
  const [getMedicine, setGetMedicine] = useState("");
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [toggleButton, setToggleButton] = useState(true);
  const [visible, setVisible] = useState(true);
  const [medicineType, setMedicineType] = useState("");
  const [gender, setGender] = useState("");
  const search = useLocation().search;

  const userId = new URLSearchParams(search).get("userId");
  const orderId = new URLSearchParams(search).get("orderId");

  useEffect(() => {
    getPrescription(userId, orderId);
  }, [userId, orderId]);

  const getPrescription = async (userId, orderId) => {
    let res = await getUserPrescription(userId, orderId);

    let dataArr = [];
    dataArr.push(res);
    setGender(res?.customer?.gender);
    setDatas(dataArr);
  };
  const options = {
    orientation: "p",
    unit: "pt",
    format: [470, 3000],
    putOnlyUsedFonts: true,
  };

  useEffect(() => {
    const cordLink = document.getElementById("traya_link");
    let yLinkVal;
    let xLinkVal;
    if (cordLink) {
      yLinkVal = cordLink.getBoundingClientRect().top;
      xLinkVal = cordLink.getBoundingClientRect().left;
    }
    const cordWat = document.getElementById("wat");
    let yWatVal;
    let xWatVal;
    if (cordWat) {
      yWatVal = cordWat.getBoundingClientRect().top;
      xWatVal = cordWat.getBoundingClientRect().left;
    }

    setXLinkCords(xLinkVal);
    setYLinkCords(yLinkVal);
    setXWatCords(xWatVal);
    setYWatCords(yWatVal);
  });

  const downloadDocument = () => {
    const pdf = new jsPdf(options);
    pdf.html(document.getElementById("downloadPrescription"), {
      callback: function () {
        pdf.link(xWatCords - 500, yWatCords, 40, 40, {
          url: "https://api.whatsapp.com/send/?phone=918828006272&text=Hey%21+I+need+help+regarding+my+prescription&type=phone_number&app_absent=0",
        });

        pdf.link(xLinkCords - 300, yLinkCords, 100, 50, {
          url: "https://traya.health/",
        });

        pdf.save("Prescription.pdf");
        window.open(pdf.output("bloburl"));
      },
    });
    document.getElementById("btn").style.display = "none";
    document.getElementById("prescriptionBody").style.marginLeft = "10%";
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const handleClick = () => {
    setToggleButton(!toggleButton);
    setVideoLink("");
  };

  const openSlotForm = () => {
    window.open("https://form.traya.health/v2/book-appointment", "_blank");
  };

  const openChatLink = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=918828006272&text=Hey%21+I+have+a+query&type=phone_number&app_absent=0",
      "_blank"
    );
  };

  const openDietPlan = () => {
    window.open("https://form.traya.health/pages/dietplan", "_blank");
  };

  return (
    <div className="prescriptionBox">
      <div id="downloadPrescription">
        <div id="prescriptionBody">
          {datas.map((data) => {
            let prescriptionData = [];
            let defaultPrescriptionData = [];
            const prescription = data.order.prescription;

            // if (prescription.length > 0) {
            //   let prescArr = [];
            //   prescArr.push(prescription);

            //   let printData = [];
            //   let recommendedPrescriptionData = [];

            //   prescArr.map((prescArray) => {
            //     prescArray.map((prescription) => {
            //       if (prescription.version === 1) {
            //         const prescriptionList = prescription.prescription;
            //         prescriptionData.push(prescriptionList);
            //       } else {
            //         const prescriptionList = JSON.parse(
            //           prescription.prescription
            //         ).prescription;
            //         prescriptionList.map((prescriptionList) => {
            //           if (prescriptionList.showRecommendation === false) {
            //             printData.push(prescriptionList);
            //           } else {
            //             recommendedPrescriptionData.push(prescriptionList);
            //           }
            //         });
            //         return (prescriptionData = [
            //           ...printData,
            //           ...recommendedPrescriptionData,
            //         ]);
            //       }
            //     });
            //   });

            //   prescriptionData = prescriptionData.reduce(
            //     (finalArr, current) => {
            //       let obj = finalArr.find(
            //         (item) => item.Medicine === current.Medicine
            //       );

            //       if (obj) {
            //         return finalArr;
            //       }
            //       return finalArr.concat([current]);
            //     },
            //     []
            //   );
            // } else {
            //   defaultPrescriptionData = data.prescription;
            // }

            defaultPrescriptionData = data.prescription;

            let userAnalysis;
            let keys;
            let rootCause = "";
            let rootCauseArr = [];
            // let doctor = data?.order?.doctor;
            let doctor = data?.first_order_doctor;
            let non_otc = data?.non_otc;

            let FooterImage;
            if (data.User_Analysis) {
              userAnalysis = data.User_Analysis;
              keys = Object.keys(userAnalysis);
              keys.map((key) => {
                switch (key) {
                  case "sleep":
                    rootCause = "Sleep";
                    break;
                  case "stress":
                    rootCause = "Stress";
                    break;
                  case "gut_health":
                    rootCause = "Gut Health";
                    break;
                  case "energy":
                    rootCause = "Energy";
                    break;
                  default:
                    return;
                }
                rootCauseArr.push(rootCause);
              });
            }

            if (doctor !== null) {
              if (doctor?.first_name.startsWith("Dr Harish Balaji")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Harish_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Aakanksha Gupta")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Aakanksha_Sign.png`;
              } else if (
                doctor?.first_name.startsWith("Dr Siddhi") ||
                doctor?.first_name.startsWith("Dr Siddhi Sonawane")
              ) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Siddhi_Sign.png`;
              } else if (
                doctor?.first_name.startsWith("Dr Kalyani") ||
                doctor?.first_name.startsWith("Doctor Kalyani Deshmukh")
              ) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Kalyani_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Shefali Saini")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Shefali_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Dhwani Saxena")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Dhwani_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Shreya Deoghare")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Shreya_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Rashi Soni")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Rashi_Sign.png`;
              } else if (
                doctor?.first_name.startsWith("Dr Sonia Tekchandani")
              ) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Sonia_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Sonal Makhija")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Sonal_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Vandana Verma")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Vandana_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Divya Poulose")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Dr_Divya_Sign.png`;
              } else if (doctor?.first_name.startsWith("Dr Komal Rathi")) {
                FooterImage = `${CDN_BASE_URL}doctor_signature/Komal Rathi.png`;
              }
            }

            return (
              <>
                <div className="container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <img
                        alt="Logo"
                        src={require("../../assets/Traya_Rx.png")}
                        width="143px"
                        height="36px"
                      />
                    </div>
                    <div>
                      <div className="toggleapp  px-[4%] xl:px-[15%]">
                        <div onClick={handleClick} className="toggle">
                          {toggleButton ? (
                            <>
                              <div
                                className="toggle-left"
                                style={{
                                  color: gender === "F" ? "#D88673" : "#B7D340",
                                  boxShadow:
                                    "1.19276px 1.19276px 4.77104px rgba(0, 0, 0, 0.16)",
                                  fontFamily: "Nunito Sans",
                                  fontStyle: "normal",
                                  fontWeight: "800",
                                }}
                              >
                                English
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0 auto",
                                  fontFamily: "Mukta",
                                  fontStyle: "normal",
                                  fontWeight: "800",
                                  color: "#B9B9B9",
                                }}
                              >
                                हिन्दी
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  fontFamily: "Nunito Sans",
                                  fontStyle: "normal",
                                  fontWeight: "800",
                                  color: "#B9B9B9",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "0 auto",
                                }}
                              >
                                English
                              </div>
                              <div
                                className="toggle-right"
                                style={{
                                  color: gender === "F" ? "#D88673" : "#B7D340",
                                  boxShadow:
                                    "1.19276px 1.19276px 4.77104px rgba(0, 0, 0, 0.16)",
                                  fontFamily: "Mukta",
                                  fontStyle: "normal",
                                  fontWeight: "800",
                                }}
                              >
                                हिन्दी
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="userDetailsContainer">
                    <div className="userDetail">
                      <span
                        style={{
                          fontFamily: "Nunito Sans, sans-serif",
                          fontStyle: "normal",
                          fontWeight: "800",
                          fontSize: "20px",
                          lineHeight: "22px",
                          color: "#414042",
                          opacity: "0.5",
                        }}
                      >
                        {data?.customer?.first_name}
                      </span>
                      {data.User_Analysis?.age && (
                        <span
                          style={{
                            fontFamily: "Nunito Sans, sans-serif",
                            fontStyle: "italic",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "22px",
                            color: "#414042",
                            opacity: "0.5",
                          }}
                        >
                          {", "}
                          {data.User_Analysis?.age}
                        </span>
                      )}
                      {data.customer?.gender && (
                        <span
                          style={{
                            fontFamily: "Nunito Sans, sans-serif",
                            fontStyle: "italic",
                            fontWeight: "400",
                            fontSize: "20px",
                            lineHeight: "22px",
                            color: "#414042",
                            opacity: "0.5",
                          }}
                        >
                          {", "}
                          {data.customer?.gender === "M" && "Male"}
                          {data.customer?.gender === "F" && "Female"}
                          {data.customer?.gender === null && ""}
                        </span>
                      )}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: "55px",
                        marginTop: "0px",
                        marginLeft: "-15px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "25px",
                          marginTop: "5px",
                          marginLeft: "0px",
                        }}
                      >
                        {data.order.order_display_id && (
                          <>
                            <span
                              style={{
                                fontFamily: "Nunito Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "800",
                                fontSize: "16px",
                                lineHeight: "12px",
                                color: "#414042",
                                opacity: "0.5",
                              }}
                            >
                              Order ID :{" "}
                            </span>
                            <span
                              style={{
                                fontFamily: "Nunito Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "12px",
                                color: "#414042",
                                opacity: "0.5",
                              }}
                            >
                              {data.order?.order_display_id}
                            </span>
                          </>
                        )}
                        <br />
                        {data?.order?.prescription[0]?.created_at && (
                          <>
                            <span
                              style={{
                                fontFamily: "Nunito Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "800",
                                fontSize: "16px",
                                lineHeight: "12px",
                                color: "#414042",
                                opacity: "0.5",
                              }}
                            >
                              Date :{" "}
                            </span>
                            <span
                              style={{
                                fontFamily: "Nunito Sans, sans-serif",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "12px",
                                color: "#414042",
                                opacity: "0.5",
                              }}
                            >
                              {dayjs(
                                new Date(
                                  data?.order?.prescription[0]?.created_at
                                )
                              ).format("DD MMMM, YYYY")}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      height: "auto",
                      marginTop: "35px",
                      backgroundColor: "#F9F8F8",
                      padding: "3% 4%",
                      borderRadius: "4px",
                    }}
                    className="content-box"
                  >
                    <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "800",
                        color: "#414042",
                        fontSize: "14px",
                      }}
                    >
                      Current diagnosis :
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500",
                        color: "#414042",
                        fontSize: "13px",
                        marginTop: "5px",
                      }}
                    >
                      {data.User_Analysis?.gender === "M" && (
                        <>
                          {data.Recommendations?.Type_of_hair_loss && (
                            <span
                              style={{
                                fontWeight: "500",
                                fontStyle: "normal",
                                fontSize: "13px",
                                lineHeight: "19px",
                              }}
                            >
                              {" "}
                              {data.Recommendations?.Type_of_hair_loss}
                            </span>
                          )}
                          {data.User_Analysis?.stage && (
                            <span
                              style={{
                                fontWeight: "500",
                                fontStyle: "normal",
                                fontSize: "13px",
                                lineHeight: "19px",
                              }}
                            >
                              {", "}
                              {data.User_Analysis?.stage}
                            </span>
                          )}
                        </>
                      )}

                      {data.User_Analysis?.gender === "F" && (
                        <>
                          <span
                            style={{
                              fontWeight: "500",
                              fontStyle: "normal",
                              fontSize: "13px",
                              lineHeight: "19px",
                            }}
                          >
                            Female pattern hair loss
                          </span>
                          {data.User_Analysis?.stages && (
                            <span
                              style={{
                                fontWeight: "500",
                                fontStyle: "normal",
                                fontSize: "13px",
                                lineHeight: "19px",
                              }}
                            >
                              {", "}
                              {data.User_Analysis?.stages}
                            </span>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      marginTop: "10px",
                      backgroundColor: "#F9F8F8",
                      padding: "3% 4%",
                      borderRadius: "4px",
                    }}
                    className="content-box"
                  >
                    <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "800",
                        color: "#414042",
                        fontSize: "14px",
                      }}
                    >
                      Root causes :
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "Nunito Sans, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500",
                        color: "#414042",
                        fontSize: "13px",
                        marginTop: "5px",
                      }}
                    >
                      {rootCauseArr.map((root, i) => {
                        return (
                          <span key={i}>
                            {i > 0 && ", "}

                            {root}
                          </span>
                        );
                      })}
                    </span>
                  </div>
                  {data.Recommendations?.Max_months && (
                    <div
                      style={{
                        height: "auto",
                        marginTop: "10px",
                        backgroundColor: "#F9F8F8",
                        padding: "3% 4%",
                        marginBottom: "20px",
                        borderRadius: "4px",
                      }}
                      className="content-box"
                    >
                      <span
                        style={{
                          fontFamily: "Nunito Sans, sans-serif",
                          fontStyle: "normal",
                          fontWeight: "800",
                          color: "#414042",
                          fontSize: "14px",
                        }}
                      >
                        Treatment duration :
                      </span>
                      <br />
                      {data.Recommendations?.Max_months && (
                        <span
                          style={{
                            fontFamily: "Nunito Sans, sans-serif",
                            fontStyle: "normal",
                            fontWeight: "500",
                            color: "#414042",
                            fontSize: "13px",
                            marginTop: "5px",
                          }}
                        >
                          Recommended for {data.Recommendations?.Max_months}{" "}
                          months of regular usage in order to see results
                        </span>
                      )}
                    </div>
                  )}

                  <div className="headerBar"></div>

                  <div>
                    {prescriptionData.length !== 0 && (
                      <div
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        {prescriptionData.map((data, i) => {
                          const quantity = data.quantity;

                          let isRecommended = "";
                          if (data.showRecommendation === true) {
                            isRecommended = "RECOMMENDED BY DOCTOR";
                          }

                          if (typeof data !== "string") {
                            let isComposition = false;
                            if (data.is_composition === true) {
                              isComposition = true;
                            }

                            let medicineImageArr = [];
                            let medicineImage;
                            let medicine = data.Medicine;
                            switch (medicine) {
                              case "Health Tatva":
                                medicineImage = HealthTatva;
                                break;
                              case "Hair Ras":
                                medicineImage = HairRas;
                                break;
                              case "Hair Vitamin":
                                medicineImage = HairVitamin;
                                break;
                              case "Defence shampoo":
                                medicineImage = DefenceShampoo;
                                break;
                              case "Consti Clear for Improved Bowel Movement":
                                medicineImage = ConstiClear;
                                break;
                              case "Digest Boost for Improved Digestive Ability":
                                medicineImage = DigestBoost;
                                break;
                              case "Gutt Shuddhi":
                                medicineImage = GuttShuddhi;
                                break;
                              case "Anti-Dandruff Shampoo":
                                medicineImage = DandruffShampoo;
                                break;
                              case "Minoxidil 5%":
                                medicineImage = Minoxidil_5;
                                break;
                              case "Minoxidil 2%":
                                medicineImage = Minoxidil_2;
                                break;
                              case "Nasal Drops":
                                medicineImage = NasalGhrit;
                                break;
                              case "Scalp Oil":
                                medicineImage = ScalpOil;
                                break;
                              case "Scalp Oil + Calm Therapy Booster":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Calm Therapy Booster Shots":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Scalp Oil + Calm Oil Shot":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Calm Oil Shot":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Scalp Oil + Dandruff Therapy Booster":
                                medicineImage = ScalpOilDandruffTherapy;
                                break;
                              case "Dandruff Therapy Booster Shots":
                                medicineImage = ScalpOilDandruffTherapy;
                                break;
                              case "Scalp Oil + Growth Oil Shot":
                                medicineImage = ScalpOilGrowthTherapy;
                                break;
                              case "Growth Oil Shot":
                                medicineImage = ScalpOilGrowthTherapy;
                                break;
                              case "Scalp Oil + Scalp Therapy Booster":
                                medicineImage = ScalpOilScalpTherapy;
                                break;
                              case "Scalp Therapy Booster Shots":
                                medicineImage = ScalpOilScalpTherapy;
                                break;
                              case "Scalp Controller":
                                medicineImage = ScalpController;
                                break;
                              case "Recap Serum":
                                medicineImage = RecapSerum;
                                break;
                              case "Ketoconazole 2% Night Lotion":
                                medicineImage = Ketoconazole;
                                break;
                              case "Kevon Lotion":
                                medicineImage = KevonLotion;
                                break;
                              case "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
                                medicineImage = CholestVati;
                                break;
                              case "Meno Santulan":
                                medicineImage = MENOSANTULAN;
                                break;
                              case "Mom Santulan":
                                medicineImage = MOMSANTULAN;
                                break;
                              case "PCOS Santulan":
                                medicineImage = PCOSSANTULAN;
                                break;
                              case "Thyro Santulan":
                                medicineImage = THYROSANTULAN;
                                break;
                              case "Damage Repair Conditioner":
                                medicineImage = Hydrate_Conditioner;
                                break;
                              case "Hydrate Damage Repair Shampoo":
                                medicineImage = Hydrate_Shampoo;
                                break;
                              default:
                                return;
                            }
                            medicineImageArr.push(medicineImage);

                            return (
                              <>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      minHeight: "100px",
                                      margin: "5% 0%",
                                    }}
                                  >
                                    <div
                                      className="imgContainer"
                                      style={{ width: "40%" }}
                                    >
                                      {medicineImageArr.map((image) => {
                                        return (
                                          <img
                                            src={image}
                                            width="auto"
                                            height="120px"
                                            alt="Medicine Image"
                                            style={{
                                              paddingLeft: "25%",
                                              paddingRight: "25%",
                                            }}
                                          />
                                        );
                                      })}
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "3%",
                                        width: "60%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                        }}
                                      >
                                        <span className="medicine-1">
                                          {getMedicineName[data.Medicine]}
                                          <span
                                            style={{
                                              color: "#414042",
                                              fontSize: "14px",
                                              fontWeight: "0px",
                                            }}
                                          >
                                            {!isRecommended && (
                                              <> x {quantity ? quantity : 1}</>
                                            )}
                                          </span>
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            fontFamily: "Mukta",
                                          }}
                                          className="medicine-2"
                                        >
                                          <span>
                                            {getMedicineDosage[data.Medicine]}
                                          </span>
                                          {isRecommended && (
                                            <span style={{ float: "right" }}>
                                              {" "}
                                              ({isRecommended})
                                            </span>
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "2px",
                                          marginLeft: "-5px",
                                          display: "flex",
                                        }}
                                      >
                                        <a
                                          style={{
                                            border: "none",
                                            backgroundColor: "white",
                                            padding: "2px",
                                          }}
                                          onClick={() => {
                                            showModal();
                                            setVideoLink(
                                              getVideoLink[data.Medicine]
                                            );
                                          }}
                                        >
                                          <img
                                            src={PlayButton}
                                            width="30px"
                                            height="30px"
                                            alt="Medicine Image"
                                          />
                                          <span
                                            style={{
                                              marginTop: "10px",
                                              fontSize: "16px",
                                              color:
                                                gender === "F"
                                                  ? "#D88673"
                                                  : "#B7D340",
                                              fontFamily: "Nunito Sans",
                                              fontStyle: "normal",
                                              fontWeight: "600",
                                            }}
                                          >
                                            Watch video
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      marginBottom: "5%",

                                      width: "100%",
                                    }}
                                  >
                                    <span className="medicine-3">
                                      {getMedicineGuide[
                                        data.Medicine
                                      ].substring(0, 60)}
                                      ....
                                      {
                                        <a
                                          style={{
                                            color:
                                              gender === "F"
                                                ? "#D88673"
                                                : "#B7D340",
                                            fontFamily:
                                              "Nunito Sans, sans-serif",
                                            opacity: "1",
                                          }}
                                          onClick={() => {
                                            showDrawer();
                                            setGetMedicine(data.Medicine);
                                          }}
                                        >
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                              textUnderlineOffset: "2px",
                                              textDecorationColor:
                                                gender === "F"
                                                  ? "#D88673"
                                                  : "#B7D340",
                                            }}
                                          >
                                            Read more
                                          </span>
                                        </a>
                                      }
                                    </span>
                                  </div>
                                  <div className="medicineBar"></div>
                                </div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <p
                                  style={{
                                    color: "black",
                                    letterSpacing: "0.3px",
                                  }}
                                >
                                  {data}
                                </p>
                              </>
                            );
                          }
                        })}
                      </div>
                    )}

                    {defaultPrescriptionData.length !== 0 && (
                      <div
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            padding: "8px",
                            borderRadius: "4px",
                            backgroundColor: "#F9F8F8",
                            fontWeight: "800",
                            color: "#414042",
                            fontFamily: "Nunito Sans",
                            display: "flex",
                            fontSize: "14px",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              paddingLeft: "1%",
                              paddingRight: "13%",
                            }}
                          >
                            Name
                          </p>

                          <p
                            style={{
                              margin: "0px",
                              paddingRight: "29%",
                            }}
                          >
                            How to use
                          </p>
                          <p
                            style={{
                              margin: "0px",
                            }}
                          >
                            Frequency
                          </p>
                        </div>

                        {defaultPrescriptionData.map((data, i) => {
                          const quantity = data.quantity;
                          let isRecommended = "";
                          if (data.showRecommendation === true) {
                            isRecommended = "RECOMMENDED BY DOCTOR";
                          }

                          if (typeof data !== "string") {
                            let isComposition = false;
                            if (data.is_composition === true) {
                              isComposition = true;
                            }

                            let medicineImageArr = [];
                            let medicineImage;
                            let medicine = data.display_name;
                            switch (medicine) {
                              case "Health Tatva":
                                medicineImage = HealthTatva;
                                break;
                              case "Hair Ras":
                                medicineImage = HairRas;
                                break;
                              case "Hair Vitamin":
                                medicineImage = HairVitamin;
                                break;
                              case "Defence shampoo":
                                medicineImage = DefenceShampoo;
                                break;
                              case "Consti Clear for Improved Bowel Movement":
                                medicineImage = ConstiClear;
                                break;
                              case "Digest Boost for Improved Digestive Ability":
                                medicineImage = DigestBoost;
                                break;
                              case "Gutt Shuddhi":
                                medicineImage = GuttShuddhi;
                                break;
                              case "Anti-Dandruff Shampoo":
                                medicineImage = DandruffShampoo;
                                break;
                              case "Minoxidil 5%":
                                medicineImage = Minoxidil_5;
                                break;
                              case "Minoxidil 2%":
                                medicineImage = Minoxidil_2;
                                break;
                              case "Nasal Drops":
                                medicineImage = NasalGhrit;
                                break;
                              case "Scalp Oil":
                                medicineImage = ScalpOil;
                                break;
                              case "Scalp Oil + Calm Therapy Booster":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Calm Therapy Booster Shots":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Scalp Oil + Calm Oil Shot":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Calm Oil Shot":
                                medicineImage = ScalpOilCalmTherapy;
                                break;
                              case "Scalp Oil + Dandruff Therapy Booster":
                                medicineImage = ScalpOilDandruffTherapy;
                                break;
                              case "Dandruff Therapy Booster Shots":
                                medicineImage = ScalpOilDandruffTherapy;
                                break;
                              case "Scalp Oil + Growth Oil Shot":
                                medicineImage = ScalpOilGrowthTherapy;
                                break;
                              case "Growth Oil Shot":
                                medicineImage = ScalpOilGrowthTherapy;
                                break;
                              case "Scalp Oil + Scalp Therapy Booster":
                                medicineImage = ScalpOilScalpTherapy;
                                break;
                              case "Scalp Therapy Booster Shots":
                                medicineImage = ScalpOilScalpTherapy;
                                break;
                              case "Scalp Controller":
                                medicineImage = ScalpController;
                                break;
                              case "Recap Serum":
                                medicineImage = RecapSerum;
                                break;
                              case "Ketoconazole 2% Night Lotion":
                                medicineImage = Ketoconazole;
                                break;
                              case "Kevon Lotion":
                                medicineImage = KevonLotion;
                                break;
                              case "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
                                medicineImage = CholestVati;
                                break;
                              case "Meno Santulan":
                                medicineImage = MENOSANTULAN;
                                break;
                              case "Mom Santulan":
                                medicineImage = MOMSANTULAN;
                                break;
                              case "PCOS Santulan":
                                medicineImage = PCOSSANTULAN;
                                break;
                              case "Thyro Santulan":
                                medicineImage = THYROSANTULAN;
                                break;
                              case "Damage Repair Conditioner":
                                medicineImage = Hydrate_Conditioner;
                                break;
                              case "Hydrate Damage Repair Shampoo":
                                medicineImage = Hydrate_Shampoo;
                                break;
                              default:
                                return;
                            }
                            medicineImageArr.push(medicineImage);
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    minHeight: "100px",
                                    margin: "5% 0% 2% 0%",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                  className="medicineContainer"
                                  key={i}
                                  onClick={() => {
                                    showDrawer();
                                    setGetMedicine(data.display_name);
                                    setMedicineType(data.type);
                                    setVideoLink(
                                      toggleButton
                                        ? getVideoLink[data.display_name]
                                        : getVideoLinkHindi[data.display_name]
                                    );
                                    setVisible(true);
                                    setGetMedicine(data.display_name);
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      fontSize: "13px",
                                      color: "#414042",
                                      fontWeight: "400",
                                      paddingRight: "1%",
                                      width: "27%",
                                      fontFamily: "Nunito Sans",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                      }}
                                    >
                                      <p
                                        style={{
                                          paddingRight: "8px",
                                        }}
                                      >
                                        {i + 1}
                                      </p>
                                      <p>
                                        {getMedicineName[data.display_name]}
                                        <br />

                                        <FaArrowUpRightFromSquare
                                          color="#414042"
                                          size={"10px"}
                                          className="mt-2"
                                        />
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      fontSize: "13px",
                                      color: "#414042",
                                      fontWeight: "400",
                                      paddingRight: "3%",
                                      width: "50%",
                                      fontFamily: toggleButton
                                        ? "Nunito Sans"
                                        : "Mukta",
                                    }}
                                  >
                                    <p>
                                      {toggleButton
                                        ? getMedicineGuide[data.display_name]
                                        : getMedicineGuideHindi[
                                            data.display_name
                                          ]}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      color: "#414042",
                                      fontWeight: "400",
                                      paddingRight: "3%",
                                      width: "23%",
                                      fontFamily: toggleButton
                                        ? "Nunito Sans"
                                        : "Mukta",
                                    }}
                                  >
                                    <p>
                                      {toggleButton
                                        ? getMedicineDosage[data.display_name]
                                        : getMedicineDosageHindi[
                                            data.display_name
                                          ]}
                                    </p>{" "}
                                  </div>
                                </div>

                                <div className="medicineBar"></div>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <p
                                  style={{
                                    color: "black",
                                    letterSpacing: "0.3px",
                                  }}
                                >
                                  {data}
                                </p>
                              </>
                            );
                          }
                        })}
                      </div>
                    )}
                  </div>
                  <div className="footerWidth">
                    <div className="footerWidth"></div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {non_otc === true && FooterImage !== undefined && (
                        <div
                          style={{
                            width: "40%",
                            marginRight: "16px",
                          }}
                          className="footerWidth mt-4 mb-4"
                        >
                          <img src={FooterImage} width="100%" />
                        </div>
                      )}
                      {non_otc === false && (
                        <div
                          style={{
                            width: "40%",
                            marginLeft: "16px",
                          }}
                          className="footerWidth mt-4 mb-4"
                        >
                          <img
                            src={`${CDN_BASE_URL}doctor_signature/Dr_Shailendra_Sign.png`}
                            width="100%"
                          />
                        </div>
                      )}
                    </div>
                    <div className="footerWidth"></div>

                    {/* <div
                      style={{
                        position: "relative",
                        width: "99%",
                      }}
                    >
                      <div style={{ padding: "5% 0%" }}>
                        <img
                          src={HairCoachBanner}
                          width="100%"
                          style={{
                            borderRadius: "4px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          margin: "0 auto",
                          position: "absolute",
                          top: "55%",
                          left: "4%",
                        }}
                      >
                        <button
                          style={{
                            width: "45%",
                            padding: "2% 0%",
                            fontSize: "16px",
                            borderRadius: "4px",
                            border: "none",
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontWeight: "500",
                            color: "#FFFFFF",
                            background: "#414042",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                          }}
                          onClick={openSlotForm}
                        >
                          Book a call
                        </button>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          margin: "0 auto",
                          position: "absolute",
                          top: "73%",
                          left: "4%",
                        }}
                      >
                        <button
                          style={{
                            width: "45%",
                            padding: "2% 0%",
                            fontSize: "16px",
                            borderRadius: "4px",
                            border: "none",
                            fontFamily: "Nunito Sans",
                            fontStyle: "normal",
                            fontWeight: "500",
                            color: "#FFFFFF",
                            background: "#414042",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
                            cursor: "pointer",
                          }}
                          onClick={openChatLink}
                        >
                          Chat now
                        </button>
                      </div>
                    </div>
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "31px",
                          color: "#414042",
                        }}
                      >
                        Traya treatment is incomplete without diet
                      </span>
                      <br />
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: "300",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#414042",
                          opacity: "0.5",
                        }}
                      >
                        Don't forget to check out your diet plan here
                      </span>
                    </div>
                    <div
                      style={{
                        position: "relative",
                        marginTop: "3%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <img src={DietPlanBanner} width="100%" />
                      </div>
                      <button
                        style={{
                          position: "absolute",
                          top: "35%",
                          margin: "0 auto",
                          width: "80%",
                          padding: "2% 0%",
                          fontSize: "22px",
                          borderRadius: "8px",
                          border: "none",
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: "500",
                          color: "#414042",
                          background: "#B7D340",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                          cursor: "pointer",
                        }}
                        onClick={openDietPlan}
                      >
                        Get my diet
                      </button>
                    </div>
                    <div style={{ marginTop: "7%" }}>
                      <span
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "17px",
                          lineHeight: "23px",
                          color: "#414042",
                        }}
                      >
                        For prescription, diet, coach, tips, community & more
                        download the Traya app
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "5%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="cta"
                    >
                      <div style={{ width: "48%" }}>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.traya.prod"
                          target="_blank"
                        >
                          <img src={PlayStore} width="100%" />
                        </a>
                      </div>

                      <div style={{ width: "48%" }}>
                        <a
                          href="https://apps.apple.com/in/app/traya/id1662943113"
                          target="_blank"
                        >
                          <img src={AppStore} width="100%" />
                        </a>
                      </div>
                    </div>
                    <div style={{ marginTop: "7%", paddingBottom: "2%" }}>
                      <p
                        style={{
                          fontFamily: "Fredoka",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "23px",
                          lineHeight: "31px",
                          color: "#B7D340",
                          textAlign: "center",
                        }}
                      >
                        So are you ready?
                        <br /> Start your Traya treatment today
                      </p>
                    </div> */}
                  </div>

                  <MedicineDrawer
                    open={open}
                    onClose={onClose}
                    medicine={getMedicine}
                    toggleButton={toggleButton}
                    videoLink={videoLink}
                    setVideoLink={setVideoLink}
                    setVisible={setVisible}
                    visible={visible}
                    medicineType={medicineType}
                  />
                  <VideoModal
                    openModal={openModal}
                    onCloseModal={onCloseModal}
                    videoLink={videoLink}
                    setVideoLink={setVideoLink}
                    setVisible={setVisible}
                    visible={visible}
                    medicine={getMedicine}
                  />
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
const getVideoLinkHindi = {
  "Health Tatva": isMobile
    ? "https://www.youtube.com/shorts/xKp_pQH6YAE?autoplay=1"
    : "https://www.youtube.com/watch?v=5ycCtFCMifY?autoplay=1",
  "Hair Ras": isMobile
    ? "https://www.youtube.com/shorts/DVR2PMw7ZQA?autoplay=1"
    : "https://www.youtube.com/watch?v=7uUOMtPDbmI?autoplay=1",
  "Hair Vitamin": isMobile
    ? "https://www.youtube.com/watch?v=t-34I8aW1KM?autoplay=1"
    : "https://www.youtube.com/watch?v=w2G43ELxaGw?autoplay=1",
  "Defence shampoo": isMobile
    ? "https://www.youtube.com/shorts/SW3Hba_-sIk?autoplay=1"
    : "https://www.youtube.com/watch?v=L_I51WqZf4A?autoplay=1",
  "Consti Clear for Improved Bowel Movement": isMobile
    ? "https://www.youtube.com/watch?v=CoOZBCl0IFo?autoplay=1"
    : "https://www.youtube.com/watch?v=SSHqy2h9cG0?autoplay=1",
  "Digest Boost for Improved Digestive Ability": isMobile
    ? "https://www.youtube.com/watch?v=JAOnOYBzwao?autoplay=1"
    : "https://www.youtube.com/watch?v=whZixQ5Nl2I?autoplay=1",
  "Gutt Shuddhi": isMobile
    ? "https://www.youtube.com/watch?v=J41_l8-t1QY?autoplay=1"
    : "https://www.youtube.com/watch?v=ns6HDbThgt8?autoplay=1",
  "Anti-Dandruff Shampoo": isMobile
    ? "https://www.youtube.com/watch?v=iQUWuFXTTrM?autoplay=1"
    : "https://www.youtube.com/watch?v=MG4ba4zxYJM?autoplay=1",
  "Minoxidil 5%": isMobile
    ? "https://www.youtube.com/watch?v=99tnTOWdrCk?autoplay=1"
    : "https://www.youtube.com/watch?v=L-OhiznzZ8c?autoplay=1",
  "Minoxidil 2%": isMobile
    ? "https://www.youtube.com/watch?v=99tnTOWdrCk?autoplay=1"
    : "https://www.youtube.com/watch?v=L-OhiznzZ8c?autoplay=1",
  "Nasal Drops": isMobile
    ? "https://www.youtube.com/shorts/MO9vMpZmFhQ?autoplay=1"
    : "https://www.youtube.com/watch?v=71jmQAiGsKU?autoplay=1",
  "Scalp Oil": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Oil + Calm Therapy Booster": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Calm Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Oil + Calm Oil Shot": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Calm Oil Shot": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Oil + Dandruff Therapy Booster": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Dandruff Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Oil + Growth Oil Shot": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Growth Oil Shot": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Oil + Scalp Therapy Booster": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/watch?v=xsPbRU-hLS4?autoplay=1"
    : "https://www.youtube.com/watch?v=l6fcL4jTDZI?autoplay=1",
  "Scalp Controller": "",
  "Recap Serum": isMobile
    ? "https://www.youtube.com/shorts/HxndkWGDoB0?autoplay=1"
    : "https://www.youtube.com/watch?v=KSEEsTq6vXc?autoplay=1",
  "Ketoconazole 2% Night Lotion": isMobile
    ? "https://www.youtube.com/watch?v=ZmeDsGvuDtE?autoplay=1"
    : "https://www.youtube.com/watch?v=Oc3nR7vO0LE?autoplay=1",
  "Kevon Lotion": isMobile
    ? "https://www.youtube.com/watch?v=ZmeDsGvuDtE?autoplay=1"
    : "https://www.youtube.com/watch?v=Oc3nR7vO0LE?autoplay=1",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol": isMobile
    ? "https://www.youtube.com/watch?v=VWJTH4EVdL0?autoplay=1"
    : "https://www.youtube.com/watch?v=ZIhPYby6WMA?autoplay=1",
  "Meno Santulan": isMobile
    ? "https://www.youtube.com/watch?v=i3i40ebDV-0?autoplay=1"
    : "https://www.youtube.com/watch?v=6Lk1WQ7fe10?autoplay=1",
  "Mom Santulan": isMobile
    ? "https://www.youtube.com/watch?v=fdl-HV-ZZwU?autoplay=1"
    : "https://www.youtube.com/watch?v=z59q6SGYcDg?autoplay=1",
  "PCOS Santulan": isMobile
    ? "https://www.youtube.com/watch?v=HVlO16nrzsA?autoplay=1"
    : "https://www.youtube.com/watch?v=uS8K1rjtP_M?autoplay=1",
  "Thyro Santulan": isMobile
    ? "https://www.youtube.com/watch?v=caDJkPmiYV8?autoplay=1"
    : "https://www.youtube.com/watch?v=t7cn7VqlcKg?autoplay=1",
  "Damage Repair Conditioner":
    "https://www.youtube.com/watch?v=hktqiCTF7qA?autoplay=1",
  "Hydrate Damage Repair Shampoo":
    "https://www.youtube.com/watch?v=hktqiCTF7qA?autoplay=1",
};

const getVideoLink = {
  "Health Tatva": isMobile
    ? "https://www.youtube.com/shorts/0wU8Ew0YS9E?autoplay=1"
    : "https://www.youtube.com/watch?v=wG9JSPg1kuk?autoplay=1",
  "Hair Ras": isMobile
    ? "https://www.youtube.com/shorts/CzPvgdeZ7bw?autoplay=1"
    : "https://www.youtube.com/watch?v=cwQa2f08XeI?autoplay=1",
  "Hair Vitamin": isMobile
    ? "https://www.youtube.com/shorts/1fcfx9X79Gg?autoplay=1"
    : "https://www.youtube.com/watch?v=LN_sdqUhXIg?autoplay=1",
  "Defence shampoo": isMobile
    ? "https://www.youtube.com/shorts/e4g2wUBV8rU?autoplay=1"
    : "https://www.youtube.com/watch?v=m_jVHVW9vtU?autoplay=1",
  "Consti Clear for Improved Bowel Movement": isMobile
    ? "https://www.youtube.com/shorts/I3rrw18agLs?autoplay=1"
    : "https://www.youtube.com/watch?v=fwszOn1K-Ec?autoplay=1",
  "Digest Boost for Improved Digestive Ability": isMobile
    ? "https://www.youtube.com/shorts/5OcpYWnOkzU?autoplay=1"
    : "https://www.youtube.com/watch?v=L9zIT0xZgQs?autoplay=1",
  "Gutt Shuddhi": isMobile
    ? "https://www.youtube.com/shorts/1BL1j-HlX5I?autoplay=1"
    : "https://www.youtube.com/watch?v=ZvXjuGMBTrk?autoplay=1",
  "Anti-Dandruff Shampoo": isMobile
    ? "https://www.youtube.com/shorts/BleknQsZ9FA?autoplay=1"
    : "https://www.youtube.com/watch?v=Uw_nzI_Vvak?autoplay=1",
  "Minoxidil 5%": isMobile
    ? "https://www.youtube.com/shorts/W_QWL26ZR3Q?autoplay=1"
    : "https://www.youtube.com/watch?v=7vAkUGal5Lc?autoplay=1",
  "Minoxidil 2%": isMobile
    ? "https://www.youtube.com/shorts/W_QWL26ZR3Q?autoplay=1"
    : "https://www.youtube.com/watch?v=7vAkUGal5Lc?autoplay=1",
  "Nasal Drops": isMobile
    ? "https://www.youtube.com/shorts/mwJo5xXCF8U?autoplay=1"
    : "https://www.youtube.com/watch?v=hku8sVBfQIs?autoplay=1",
  "Scalp Oil": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Oil + Calm Therapy Booster": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Calm Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Oil + Calm Oil Shot": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Calm Oil Shot": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Oil + Dandruff Therapy Booster": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Dandruff Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Oil + Growth Oil Shot": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Growth Oil Shot": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Oil + Scalp Therapy Booster": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Therapy Booster Shots": isMobile
    ? "https://www.youtube.com/shorts/m_0pb-PJMFA?autoplay=1"
    : "https://www.youtube.com/watch?v=UZ-2AMMpvg8?autoplay=1",
  "Scalp Controller": "",
  "Recap Serum": isMobile
    ? "https://www.youtube.com/shorts/2XE_LP8nx7U?autoplay=1"
    : "https://www.youtube.com/watch?v=LWXJLXXu4jU?autoplay=1",
  "Ketoconazole 2% Night Lotion": isMobile
    ? "https://www.youtube.com/shorts/Aj0e_wN1pOI?autoplay=1"
    : "https://www.youtube.com/watch?v=VEb9CRJgyEw?autoplay=1",
  "Kevon Lotion": isMobile
    ? "https://www.youtube.com/shorts/Aj0e_wN1pOI?autoplay=1"
    : "https://www.youtube.com/watch?v=VEb9CRJgyEw?autoplay=1",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol": isMobile
    ? "https://www.youtube.com/shorts/jbu9X-Jl_yw?autoplay=1"
    : "https://www.youtube.com/watch?v=Q2v1jTR_WEk?autoplay=1",
  "Meno Santulan": isMobile
    ? "https://www.youtube.com/shorts/17tlpzc7JKY?autoplay=1"
    : "https://www.youtube.com/watch?v=5bv2KaUm3qc?autoplay=1",
  "Mom Santulan": isMobile
    ? "https://www.youtube.com/shorts/S-B3FwRdPHE?autoplay=1"
    : "https://www.youtube.com/watch?v=8uNs8L_3S2w?autoplay=1",
  "PCOS Santulan": isMobile
    ? "https://www.youtube.com/shorts/EW7YP1wvxr8?autoplay=1"
    : "https://www.youtube.com/watch?v=f0SsxTFYFVo?autoplay=1",
  "Thyro Santulan": isMobile
    ? "https://www.youtube.com/shorts/zsteIHHB8VM?autoplay=1"
    : "https://www.youtube.com/watch?v=3JMsaAzQqQo?autoplay=1",
  "Damage Repair Conditioner":
    "https://www.youtube.com/watch?v=hktqiCTF7qA?autoplay=1",
  "Hydrate Damage Repair Shampoo":
    "https://www.youtube.com/watch?v=hktqiCTF7qA?autoplay=1",
};

const getMedicineName = {
  "Health Tatva": "Health Tatva",
  "Hair Ras": "Hair Ras",
  "Hair Vitamin": "Hair Vitamin",
  "Defence shampoo": "Defence Shampoo",
  "Consti Clear for Improved Bowel Movement": "Consti Clear",
  "Digest Boost for Improved Digestive Ability": "Digest Boost",
  "Gutt Shuddhi": "Gutt Shuddhi",
  "Anti-Dandruff Shampoo": "Anti-Dandruff Shampoo",
  "Minoxidil 5%": "Minoxidil 5%",
  "Minoxidil 2%": "Minoxidil 2%",
  "Nasal Drops": "Nasal Drops",
  "Scalp Oil": "Scalp Oil",
  "Scalp Oil + Calm Therapy Booster": "Scalp Oil + Calm Therapy Booster",
  "Calm Therapy Booster Shots": "Calm Therapy Booster Shots",
  "Scalp Oil + Calm Oil Shot": "Scalp Oil + Calm Oil Shot",
  "Calm Oil Shot": "Calm Oil Shot",
  "Scalp Oil + Dandruff Therapy Booster":
    "Scalp Oil + Dandruff Therapy Booster",
  "Dandruff Therapy Booster Shots": "Dandruff Therapy Booster Shots",
  "Scalp Oil + Growth Oil Shot": "Scalp Oil + Growth Oil Shot",
  "Growth Oil Shot": "Growth Oil Shot",
  "Scalp Oil + Scalp Therapy Booster": "Scalp Oil + Scalp Therapy Booster",
  "Scalp Therapy Booster Shots": "Scalp Therapy Booster Shots",
  "Scalp Controller": "Scalp Controller",
  "Recap Serum": "Recap Serum",
  "Ketoconazole 2% Night Lotion": "Ketoconazole 2% Night Lotion",
  "Kevon Lotion": "Kevon Lotion",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Cholest Vati",
  "Meno Santulan": "Meno Santulan",
  "Mom Santulan": "Mom Santulan",
  "PCOS Santulan": "PCOS Santulan",
  "Thyro Santulan": "Thyro Santulan",
  "Damage Repair Conditioner": "Hydrating Conditioner",
  "Hydrate Damage Repair Shampoo": "Hydrating Shampoo",
};

const getMedicineGuide = {
  "Health Tatva":
    "Take 1 tablet of  Health Tatva after breakfast, 1 tablet of  Health Tatva after dinner everyday with a glass of water.",
  "Hair Ras":
    "Take 2 tablets of Hair Ras after breakfast, 2 tablets of Hair Ras after dinner everyday with a glass of water.",
  "Hair Vitamin":
    "Take 1 tablet of Hair Vitamin after breakfast everyday with a glass of water.",
  "Defence shampoo":
    "Take 2-3 pumps and lather well into your scalp. Massage using your hands for 2-3 minutes and rinse off with water.",
  "Consti Clear for Improved Bowel Movement":
    "Take 2 tablets of Consti Clear after dinner with a glass of water.",
  "Digest Boost for Improved Digestive Ability":
    "Take 1 tablet of Digest Boost after breakfast, 1 tablet of Digest Boost after dinner everyday with a glass of water.",
  "Gutt Shuddhi":
    "Take 1 tablet of Gutt Shuddhi after dinner everyday with a glass of water.",
  "Anti-Dandruff Shampoo":
    "Take enough amount and lather well into your scalp. Massage using your hands for 3 minutes and rinse off with water.",
  "Minoxidil 5%":
    "Use the dropper to apply on a clean and dry scalp. Do not let it drip down your face, apply only on affected areas.",
  "Minoxidil 2%":
    "Use the dropper to apply on a clean and dry scalp. Do not let it drip down your face, apply only on affected areas.",
  "Nasal Drops":
    "Put 3 drops in each nostril in laying down position. Insert the bottle in warm water before use.",
  "Scalp Oil": "Use twice a week",
  "Scalp Oil + Calm Therapy Booster":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Calm Therapy Booster Shots":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Calm Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Dandruff Therapy Booster":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Dandruff Therapy Booster Shots":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Growth Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Growth Oil Shot":
    "Empty the small bottle in Scalp oil. Shake well before use. Gently massage into your scalp for 5 minutes.",
  "Scalp Oil + Scalp Therapy Booster": "Use twice a week",
  "Scalp Therapy Booster Shots": "Use twice a week",
  "Scalp Controller": "",
  "Recap Serum":
    "Use the dropper to apply on a clean and dry scalp on areas where you see hair loss or thinning.",
  "Ketoconazole 2% Night Lotion":
    "Take a small amount and apply a thin layer over the scalp before bedtime.",
  "Kevon Lotion":
    "Take a small amount and apply a thin layer over the scalp before bedtime.",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Take 2 tablets of Cholest Vati after breakfast, 2 tablets of Cholest Vati after dinner everyday with a glass of water.",
  "Meno Santulan":
    "Take 2 tablets after breakfast, 1 after dinner with a glass of water.",
  "Mom Santulan":
    "Take 2 tablets of Meno Santulan after breakfast, 2 tablets of Meno Santulan after dinner everyday with a glass of water.",
  "PCOS Santulan":
    "Take 2 tablets of PCOS Santulan after breakfast, 2 tablets of PCOS Santulan after dinner everyday with a glass of water.",
  "Thyro Santulan":
    "Take 2 tablets of Thyro Santulan after breakfast, 2 tablets of Thyro Santulan after dinner everyday with a glass of water.",
  "Damage Repair Conditioner":
    "Thoroughly rinse your hair with water and ensure your hair feels squeaky clean.",
  "Hydrate Damage Repair Shampoo":
    "Start by wetting your hair thoroughly with water. Make sure your hair is completely wet.",
};

const getMedicineGuideHindi = {
  "Health Tatva":
    "प्रतिदिन नाश्ते के बाद 1 हेल्थ तत्वा की गोली लें, रात के खाने के बाद भी 1 हेल्थ तत्वा की गोली, एक ग्लास पानी के साथ लें।",
  "Hair Ras":
    "नाश्ते के बाद दो टेबलेट लें, रात के खाने के बाद एक ग्लास पानी के साथ दो टेबलेट लें।",
  "Hair Vitamin":
    "प्रतिदिन सुबह नाश्ते के बाद एक ग्लास पानी के साथ एक टेबलेट लें।",
  "Defence shampoo":
    "2-3 पंप लें और अपने स्कैल्प में अच्छी तरह से फोम बनाएँ। 2-3 मिनट तक अपने हाथों से मालिश करें और पानी से धो दें।",
  "Consti Clear for Improved Bowel Movement":
    "२ टेबलेट रात के खाने के बाद पानी के साथ खा लें।",
  "Digest Boost for Improved Digestive Ability":
    "प्रतिदिन नाश्ते के बाद 1 डाइजेस्ट बूस्ट की गोली लें, रात के खाने के बाद भी 1 डाइजेस्ट बूस्ट की गोली, एक ग्लास पानी के साथ लें।",
  "Gutt Shuddhi":
    "प्रतिदिन रात के खाने के बाद एक ग्लास पानी के साथ एक टेबलेट लें।",
  "Anti-Dandruff Shampoo":
    "ना बहुत कम, ना बहुत ज्यादा मात्रा में शैम्पू लें और सर पर अच्छी तरह से लगायें। ३ मिनट तक मालिश करें और फिर अच्छी तरह से धो लें।",
  "Minoxidil 5%":
    "साफ और सूखे स्कैल्प पर ड्रॉपर का उपयोग करें। इसे अपने चेहरे पर टपकने नहीं दें, केवल प्रभावित क्षेत्रों पर लागू करें।",
  "Minoxidil 2%":
    "साफ और सूखे स्कैल्प पर ड्रॉपर का उपयोग करें। इसे अपने चेहरे पर टपकने नहीं दें, केवल प्रभावित क्षेत्रों पर लागू करें।",
  "Nasal Drops":
    "लेटते हुए स्थिति में प्रत्येक नाक में 3 बूंद डालें। उपयोग से पहले बोतल को गरम पानी में डालें।",
  "Scalp Oil": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Calm Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Calm Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Calm Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Dandruff Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Dandruff Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Growth Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Growth Oil Shot":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Oil + Scalp Therapy Booster":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Therapy Booster Shots":
    "छोटी बोतल को स्कैल्प ऑयल में खाली करें। उपयोग से पहले अच्छी तरह से हिलाएँ। अपने स्कैल्प को 5 मिनट के लिए हल्के हाथ से मालिश करें।",
  "Scalp Controller": "",
  "Recap Serum":
    "ड्रॉपर का उपयोग करके, साफ और सूखे स्कैल्प पर लागू करें जहाँ आप बालों का झड़ना या कमजोर होने का अनुभव कर रहे हो।",
  "Ketoconazole 2% Night Lotion":
    "सोने से पहले थोड़ी सी मात्रा लें और स्कैल्प पर एक पतली परत लगाएँ।",
  "Kevon Lotion": "सोने से पहले थोड़ा सा लें और स्कैल्प पर एक पतली परत लगाएं।",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "प्रतिदिन नाश्ते के बाद 2 चोलेस्ट वटी की गोलियां लें, रात के खाने के बाद भी 2 गोलियां, पानी के एक ग्लास पानी के साथ लें।",
  "Meno Santulan":
    "प्रतिदिन नाश्ते के बाद 2 मेनो संतुलन की गोलियां लें, रात के खाने के बाद भी 2 मेनो संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "Mom Santulan":
    "प्रतिदिन नाश्ते के बाद 2 मोम संतुलन की गोलियां लें, रात के खाने के बाद भी 2 मोम संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "PCOS Santulan":
    "प्रतिदिन नाश्ते के बाद 2 पीसीओएस संतुलन की गोलियां लें, रात के खाने के बाद भी 2 पीसीओएस संतुलन की गोलियां, पानी के एक ग्लास के साथ लें।",
  "Thyro Santulan":
    "नाश्ते के बाद दो टेबलेट लें, रात के खाने के बाद एक ग्लास पानी के साथ दो टेबलेट लें।",
  "Damage Repair Conditioner":
    "अब, अपने बालों को पानी से धो लें, यह सुनिश्चित कर लें कि आपके बाल अच्छी तरह से साफ हो गए हैं।",
  "Hydrate Damage Repair Shampoo":
    "अपने बालों को पानी से अच्छी तरह गीला कर लें।सुनिश्चित करें कि आपके बाल पूरी तरह गीले हैं।",
};

const getMedicineDosage = {
  "Health Tatva": "One tablet twice a day",
  "Hair Ras": "Two tablets twice a day",
  "Hair Vitamin": "Take one tablet in the morning",
  "Defence shampoo": "Use twice a week",
  "Consti Clear for Improved Bowel Movement": "Take two tablets at night",
  "Digest Boost for Improved Digestive Ability": "One tablet twice a day",
  "Gutt Shuddhi": "Take one tablet at night",
  "Anti-Dandruff Shampoo": "Use twice a week",
  "Minoxidil 5%": "Use 1ml, twice a day",
  "Minoxidil 2%": "Use 1ml, twice a day",
  "Nasal Drops": "Use daily before bedtime",
  "Scalp Oil": "Use twice a week",
  "Scalp Oil + Calm Therapy Booster": "Use twice a week",
  "Calm Therapy Booster Shots": "Use twice a week",
  "Scalp Oil + Calm Oil Shot": "Use twice a week",
  "Calm Oil Shot": "Use twice a week",
  "Scalp Oil + Dandruff Therapy Booster": "Use twice a week",
  "Dandruff Therapy Booster Shots": "Use twice a week",
  "Scalp Oil + Growth Oil Shot": "Use twice a week",
  "Growth Oil Shot": "Use twice a week",
  "Scalp Oil + Scalp Therapy Booster": "Use twice a week",
  "Scalp Therapy Booster Shots": "Use twice a week",
  "Scalp Controller": "",
  "Recap Serum": "Apply daily at night",
  "Ketoconazole 2% Night Lotion": "Apply on affected areas at night",
  "Kevon Lotion": "Apply on affected areas at night",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "Two tablets twice a day",
  "Meno Santulan": "Two tablets twice a day",
  "Mom Santulan": "Two tablets twice a day",
  "PCOS Santulan": "Two tablets twice a day",
  "Thyro Santulan": "Take two tablets twice a day",
  "Damage Repair Conditioner": "Use as required",
  "Hydrate Damage Repair Shampoo": "Use as required",
};

const getMedicineDosageHindi = {
  "Health Tatva": "दिन में दो बार एक टैबलेट लें।",
  "Hair Ras": "दिन में दो बार दो टेबलेट लें।",
  "Hair Vitamin": "सुबह एक टेबलेट लें।",
  "Defence shampoo": "हफ्ते में दो बार इस्तेमाल करें।",
  "Consti Clear for Improved Bowel Movement": "रात में दो टैबलेट लें।",
  "Digest Boost for Improved Digestive Ability":
    "दिन में दो बार एक टैबलेट लें।",
  "Gutt Shuddhi": "रात में एक टेबलेट लें।",
  "Anti-Dandruff Shampoo": "हफ्ते में दो बार इस्तेमाल करें।",
  "Minoxidil 5%": "1 मिलीलीटर, दिन में दो बार उपयोग करें।",
  "Minoxidil 2%": "1 मिलीलीटर, दिन में दो बार उपयोग करें।",
  "Nasal Drops": "रोज सोने से पहले इस्तेमाल करें।",
  "Scalp Oil": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Calm Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Calm Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Calm Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Dandruff Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Dandruff Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Growth Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Growth Oil Shot": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Oil + Scalp Therapy Booster": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Therapy Booster Shots": "हफ्ते में दो बार इस्तेमाल करें।",
  "Scalp Controller": "",
  "Recap Serum": "रोज रात में लगाएं।",
  "Ketoconazole 2% Night Lotion": "रात में प्रभावित क्षेत्रों पर लगाएँ।",
  "Kevon Lotion": "आफ़ेक्टेड एरियों पर रात में लगाएँ।",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol":
    "दिन में दो बार दो टैबलेट लें।",
  "Meno Santulan": "दिन में दो बार दो गोलियां लें।",
  "Mom Santulan": "दिन में दो बार दो गोलियां लें।",
  "PCOS Santulan": "दिन में दो बार दो गोलियां लें।",
  "Thyro Santulan": "दिन में दो बार दो टेबलेट लें।",
  "Damage Repair Conditioner": "आवश्यकता अनुसार उपयोग करें",
  "Hydrate Damage Repair Shampoo": "आवश्यकता अनुसार उपयोग करें",
};

const getMedicineDuration = {
  "Health Tatva": "8 months",
  "Hair Ras": "8 months",
  "Hair Vitamin": "3 months",
  "Defence shampoo": "",
  "Consti Clear for Improved Bowel Movement":
    "6 weeks and followed by need based",
  "Digest Boost for Improved Digestive Ability": "3 month",
  "Gutt Shuddhi": "3 months",
  "Anti-Dandruff Shampoo": "",
  "Minoxidil 5%": "",
  "Minoxidil 2%": "",
  "Nasal Drops": "1 month",
  "Scalp Oil": "",
  "Scalp Oil + Calm Therapy Booster": "",
  "Calm Therapy Booster Shots": "",
  "Scalp Oil + Calm Oil Shot": "",
  "Calm Oil Shot": "",
  "Scalp Oil + Dandruff Therapy Booster": "",
  "Dandruff Therapy Booster Shots": "",
  "Scalp Oil + Growth Oil Shot": "",
  "Growth Oil Shot": "",
  "Scalp Oil + Scalp Therapy Booster": "",
  "Scalp Therapy Booster Shots": "",
  "Scalp Controller": "",
  "Recap Serum": "8 months",
  "Ketoconazole 2% Night Lotion": "1 month",
  "Kevon Lotion": "1 month",
  "Cholest Vati Natural Metabolic Booster that Lowers Cholesterol": "8 months",
  "Meno Santulan": "6 months",
  "Mom Santulan": "6 months",
  "PCOS Santulan": "6 months",
  "Thyro Santulan": "6 months",
  "Damage Repair Conditioner": "",
  "Hydrate Damage Repair Shampoo": "",
};
