import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./pages/dashboard/components/Dashboard";
import FindOrderId from "./pages/findOrderId/FindOrderId";
import Prescription from "./pages/mobilePrescription/Prescription";
import NewPrescription from "./pages/prescription/components/Prescription";
import "./assets/styles/App.less";
import { environment } from "./helpers/constants";

function App() {
  const redirectToNativeUrl = () => {
    window.location.href = environment==='dev'?"https://formv2s.dev.hav-g.in/myrecords":"https://form.traya.health/myrecords"
  };
 
  return (
    <>
      <Switch>
        <Route exact path="/">
       {redirectToNativeUrl()}
          {/* <Dashboard /> */}
        </Route>
        <Route path="/find-orderId">
        { redirectToNativeUrl()}
        </Route>
        <Route path="/prescription">
        { redirectToNativeUrl()}
        </Route>
        <Route path="/my-prescription">
        { redirectToNativeUrl()}
        </Route>
      </Switch>
    </>
  );
}

export default App;  
